.header {
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  z-index: 1049;
  padding: 10px 30px 10px 22px;
  width: 100%;
  left: 0;
  right: 0;
  &.non-header {
    position: absolute;
    left: auto;
    right: 10px;
    top: 5px;
    height: auto;
    padding: 0;
    background: none;
    width: auto;
    display: inline-flex;
    .export-text {
      @include font-semi-bold(13px, normal);
      color: #222;
    }
    .ul-transform {
      transform: translate(-54px, 32px) !important;
    }
  }
  .user-btn-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .page-heading {
    padding-left: 255px;
    @include font-semi-bold(20px, 22px);
    color: #123167;
  }
  .dropdown-toggle {
    @include font-medium(14px, normal);
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0;
    color: #123167;
    background-color: unset;
    border: unset;
    padding-left: 8px;
    margin: 0;
    .icon {
      font-size: 24px;
      margin-right: 15px;
    }
    &::after {
      border: 0;
      font-family: "icomoon", sans-serif !important;
      font-size: 0;
      content: "\e906";
    }
    &.btn:focus {
      box-shadow: unset;
    }
  }
}
.dropdown-sec {
  position: relative;
}

.subscription-btn {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  .subscribed-btn {
    h5 {
      color: #919191;
      padding: 0 20px 0 0;
      @include font-medium(14px, normal);
      &:nth-child(2) {
        color: #333;
        cursor: pointer;
      }
    }
    .primary-uppercase-nospace {
      width: 201px;
      padding: 14px 24px 14px 22px;
    }
  }
  .edit-category-btn {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  h5 {
    color: #333;
    padding: 0 20px 0 0;
    @include font-medium(14px, normal);
    &:nth-child(2) {
      color: #919191;
      cursor: pointer;
    }
  }
}
