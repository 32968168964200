.form-group {
  position: relative;
  margin-bottom: 30px;
  label {
    margin: 25px 0px 10px 0px;
    display: inline-block;
  }
  &.move.active {
    .form-control {
      border-color: $input-border-active-color;
    }
  }
}

.form-row {
  margin-right: -10px;
  margin-left: -10px;
  > .col,
  > [class*="col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
}

label {
  @include font-regular(16px, 1.38);
  color: $input-label-color;
  margin-bottom: 0;
}

.invalid-feedback {
  @include font-regular(13px, 1.3);
  color: $error-color;
  justify-content: flex-end;
}

.login-form-wrapper {
  .primary {
    margin-bottom: 22px;
    display: block;
    @media (max-height: 688px) {
      margin-bottom: 12px;
    }
  }
}

.form-control {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $input-background;
  border: 1px solid $input-border-color;
  border-radius: 3px;
  color: $input-placeholder;
  padding: 12px 15px 13px 15px;
  @include font-regular(16px, 1.43);
  @include placeholder {
    color: $input-placeholder;
    opacity: 1;
  }
  &:focus {
    color: $input-placeholder;
    background-color: $input-background;
    border-color: $input-focus-border-color;
    outline: 0;
    box-shadow: none;
  }
  .was-validated .form-control:invalid,
  &.is-invalid {
    border-color: $input-border-color;
    background-image: none;
    &:focus {
      border-color: $input-focus-border-color;
      box-shadow: none;
    }
    ~ .invalid-feedback,
    ~ .invalid-tooltip {
      display: flex;
    }
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px white inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}

.label-heightlight {
  .form-control {
    border-color: $input-focus-border-color;
  }
}

.input-group-text {
  background: $input-background;
  font-size: 1.5rem;
}

.placeholder-text {
  @include font-medium(14px, 1.43);
  width: 269px;
  height: 35px;
  color: #333;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
}

.input-group-text-add {
  background-color: #e5e5e5;
  @include font-medium(12px, 1.43);
  width: 105px;
  height: 35px;
  color: #000;
}

.input-group-border {
  width: 380px;
  height: 45px;
  background-color: yellow;
}

.icon-tick {
  font-size: 74px;
  margin-bottom: 40px;
}

.p-viewer {
  z-index: 9999;
  position: relative;
}

.fa-eye {
  color: #000;
}

// checkbox and Radio
.form-check-input {
  width: 20px;
  height: 20px;
  &:checked {
    background-color: #fff;
    border-color: #885d40 !important;
  }
  &:checked[type="checkbox"] {
    background-image: url(./../../../assets/images/svg/checkbox-tick.svg) !important;
    background-size: auto;
    background-position-x: center;
    background-position-y: center;
  }
  &:focus {
    box-shadow: unset;
  }
  &:checked[type="radio"] {
    background-image: url(./../../images/svg/radiobutton-active.svg) !important;
    background-size: auto;
  }
}

.form-check-input-radio {
  width: 24px;
  height: 24px;
}

.form-check-input[type="checkbox"] {
  border: 1px solid #616161;
  border-radius: unset;
  cursor: pointer;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
  margin-bottom: 7px;
  cursor: pointer;
}

.tag {
  @include font-regular(14px, normal);
  position: relative;
  display: block;
  color: #696969;
}

.regular-checkbox {
  display: none;
}

textarea {
  resize: none;
  &.form-control {
    &::first-letter {
      text-transform: uppercase;
    }
    ~ .invalid-feedback {
      position: static;
    }
  }
}

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable {
  min-height: 500px;
}

.form-select:focus {
  border-color: #e7dfc3;
}

.form-check-qr {
  padding: 0;
}

.state-input {
  .plan-form-select {
    width: 100%;
    color: #222;
    @include font-regular(16px, normal);
    border: 1px solid #616161 !important;
  }
}
