.main-right-wrapper {
    display: flex;
    flex-direction: column;
    flex: 100%;
    max-width: 100%;
    padding: 0 30px 0 290px;
    background-color: #f8f8f8;
}

.main-wrapper-notification {
    padding: 0 30px 0 290px;
}

.main-right-wrapper-height {
    height: 768px;
}

.delete-category-wrapper {
    padding: 0;
}