.form-group {
  margin-bottom: 0;
}
.form-control:disabled {
  opacity: 0.5;
  background-color: #fff;
  border: 1px solid rgba(71, 71, 71, 0.5);
}
#subhead-space {
  margin-top: 30px;
}

#head-bootomsapce {
  margin: 30px 0 8px 0;
}

.verified-text {
  text-transform: uppercase !important;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  color: rgb(21, 21, 21) !important;
  margin: 49px 0 8px 0 !important;
}

.success-text {
  font-family: "Open Sans", sans-serif !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: rgb(98, 98, 98);
  text-align: center !important;
  padding-bottom: 30px;
}

.verified-email-row {
  --bs-gutter-x: 0 !important;
}

#head-topmsapce {
  margin-bottom: 8px;
}

.sub-head-center {
  font-size: 13px;
  line-height: 24px;
  text-align: center;
}

.category-color {
  @include font-regular(15px, normal);
  color: #4d4d4d;
  margin: 10px 0 20px 0;
}

.form-group label {
  margin: 25px 0 10px 0;
}

.forgot-link-center {
  width: 100%;
  text-align: center;
}

.primary-uppercase {
  text-transform: uppercase;
  margin-bottom: 10px;
}

.primary-uppercase-nospace {
  text-transform: uppercase;
  padding: 14px 25px 14px 25px;
  margin-right: 30px;
  .subs-dropdown-icon {
    position: relative;
    border: none;
    padding: 0;
    bottom: 0;
    left: 5px;
  }
}

.img-responsive {
  margin-top: 33px;
}

#head-smallsize {
  @include font-shorthand(16px, normal);
  margin: 30px 0 8px 0;
}

.recorded-text {
  font-size: 14px;
  color: #3d3d3d;
}

.recorded-text-color {
  color: #686868;
  margin-top: 8px;
}

.form-action {
  .audio {
    border: 3px solid #f8f8f8;
    border-radius: 1.5px;
    margin-bottom: 20px;
  }
  .audio-img {
    position: absolute;
    height: 48px;
    margin: 20px 0 0 15px;
    width: 100px;
  }
  img {
    position: absolute;
    bottom: 12px;
    border-right: 1px solid white;
    padding-right: 15px;
  }
  .audio-action {
    background-color: white;
    padding: 54px 50px 30px 50px;
    margin-top: 30px;
    border-radius: 4px;
  }
}

#recorded-container {
  background-color: #f8f8f8;
  height: 100vh;
}

.link-expire {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  margin: 14px 0 0 0;
  img {
    position: static;
    padding: 0 8px 0 0px;
    border: none;
  }
  p {
    @include font-regular;
    @include font-shorthand(12px, normal);
    color: #6b6b6b;
  }
}

// Dashboard start here
.header {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  h1 {
    color: #000;
    font-size: 20px;
    font-weight: 500;
    margin: 26px 0 26px 64px;
  }
  .search {
    padding-right: 30px;
    @include font-shorthand(14px, normal);
    span {
      position: relative;
      left: 48px;
      border-right: 1px solid #dbdbdb;
      padding-right: 15px;
    }
    input {
      height: 40px;
      width: 250px;
      border-radius: 20px;
      border: solid 1px rgba(0, 0, 0, 0.05);
      padding: 10px 0 10px 56px;
      background-color: hsl(0, 0%, 97%);
      color: #666;
      box-sizing: border-box;
    }
  }
  .header-left-data {
    display: flex;
    align-items: center;
    justify-content: center;
    .header-right-arrow {
      img {
        padding: 0 5px 3px 0;
      }
    }
  }
}

.logout-part {
  .form-padding-top {
    width: 350px;
    border: none;
    .primary-logout-btn {
      background-color: #ff3e9d;
    }
  }
}

.subs-input-head {
  height: auto;
}

.container-file-upload {
  width: 380px;
  height: 47px;
  padding: 5px 5px 5px 0;
  background-color: #ffffff;
  border: solid 1px #dbdbdb;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  justify-content: flex-end;
  margin: 10px 0 25px 0;
}

.input-data {
  border: none;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  margin: 10px 0 25px 0;
  align-items: center;
  padding-left: 15px;
  input {
    width: 380px;
    padding: 12px 15px 13px 15px;
    background-color: #ffffff;
    &::placeholder {
      color: #000;
      @include font-medium(14px, normal);
    }
  }
}

.p-viewer {
  z-index: 9999;
  position: relative;
}

.fa-eye {
  color: #000;
  position: relative;
  right: 30px;
}

input[type="file"] {
  background-color: #ffffff;
}

.language-no-border {
  border: none;
}

.form-select {
  padding-left: 11px;
}

#select-height {
  height: 47px;
  @include font-medium(14px, normal);
  color: #333;
}

.table-body {
  .table-row {
    &:first-child {
      margin-top: 0px;
    }
  }
}

// Pagination start here
.ngx-pagination .disabled {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ngx-pagination .pagination-previous a::before {
  content: "";
  display: inline-block;
  margin-right: 0.5rem;
  background: url(./../../images/svg/pagination-arrow-2-left.svg) no-repeat
    center;
  font-size: 0;
  width: 7px;
  height: 28px;
  vertical-align: middle;
}

.ngx-pagination .pagination-previous.disabled::before {
  content: "";
  display: inline-block;
  margin-right: 2px !important;
  background: url(./../../images/svg/pagination-arrow-gray.svg) no-repeat center;
  font-size: 0;
  width: 7px;
  height: 28px;
  vertical-align: middle;
}

.ngx-pagination .pagination-next a::after {
  content: "";
  display: inline-block;
  margin-left: 0 !important;
  background: url(./../../images/svg/pagination-arrow-right.svg) no-repeat
    center;
  font-size: 0;
  width: 7px;
  height: 28px;
  vertical-align: middle;
}

.ngx-pagination .pagination-next.disabled::after {
  content: "";
  display: inline-block;
  margin-left: 0.5rem;
  background: url(./../../images/svg/play-icon.svg) no-repeat center;
  font-size: 0;
  width: 7px;
  height: 28px;
  vertical-align: middle;
}

.my-pagination .ngx-pagination .current {
  padding: 8px 25px;
  background: #fff !important;
  color: #222;
  cursor: default;
  border: 1px solid #e7dfc3;
  display: flex;
  align-items: center;
}

.pagination-next {
  border: 1px solid #e7dfc3;
  color: #222;
}

.pagination-previous {
  border: 1px solid #e7dfc3;
  color: #222;
}

.ngx-pagination {
  display: flex;
  align-items: center;
  & li {
    display: flex;
    width: 36px;
    height: 36px;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
    & a {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      width: 36px;
    }
  }
  &.disabled {
    padding: 0.4975rem 0.625rem !important;
    color: #cacaca;
    cursor: default;
  }
}

.add-picture-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  // flex-wrap: wrap;
  width: 100%;
  .add-picture-col {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

// video icon
.video-icon {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  opacity: 99 !important;
  width: 48px;
  height: 48px;
  display: block;
  img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
    border: unset !important;
  }
}

// image preview modal
.image-preview-modal-dialog {
  max-width: 605px;
  width: 100%;
  max-height: 761px;
  .image-preview-wrapper {
    max-width: 605px;
    width: 100%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .image-preview-close-btn {
    position: absolute;
    top: 10px;
    right: 0;
  }
}

.image-preview-modal-content {
  padding: 0;
  background-color: unset !important;
  border-radius: unset;
}

.qr-code-sold-wrapper {
  display: flex;
  margin: 0 -13px;
  margin-bottom: 20px;
  &.margin-botom {
    margin-bottom: 30px;
  }
  .qr-code-sold-sec {
    width: 25%;
    background-color: #fff;
    padding: 20px;
    margin: 0 13px;
    border: solid 1px #e5e5e5;
    position: relative;
    .qr-code-title {
      @include font-regular(16px, normal);
      margin-bottom: 10px;
      color: #222;
    }
    .qr-code-text {
      @include font-bold(45px, normal);
      color: #885d40;
    }
  }
}

.total-records {
  p {
    font-family: "Manrope", sans-serif;
    font-size: 20px;
    color: #885d40;
    font-weight: 400;
    span {
      font-weight: 700;
    }
  }
}

.textarea-after {
  textarea {
    padding: 30px 13px 10px 13px;
  }
  .top-textarea {
    background-color: #fff;
    width: 99%;
    height: 30px;
    position: absolute;
    top: 1px;
    left: 1px;
    right: 0px;
    border-radius: 3px;
  }
  .bottom-textarea {
    background-color: #fff;
    width: 99%;
    height: 10px;
    position: absolute;
    bottom: 0;
    bottom: 1px;
    left: 1px;
    right: 0px;
    border-radius: 3px;
  }
}
.height-112 {
  height: 112px !important;
}
.textarea-message {
  padding: 0 0;
  margin-bottom: 20px;
  .form-floating {
    position: relative;
    label {
      z-index: 2;
    }
    &:before {
      background: #fff;
      height: 30px;
      content: "";
      display: block;
      position: absolute;
      left: 10px;
      right: 15px;
      top: 2px;
      z-index: 1;
      @media (min-width: 1921px) {
        top: 2px;
      }
    }
    &:after {
      background: #fff;
      height: 10px;
      content: "";
      display: block;
      position: absolute;
      left: 10px;
      right: 15px;
      bottom: 1px;
      z-index: 1;
      @media (min-width: 1921px) {
        bottom: 2px;
      }
    }
  }
  textarea {
    height: 190px;
    position: relative;
  }
}

.sales-drop-sec {
  margin: 40px 0 0 0;
  position: relative;
  a {
    @include font-bold(11px, normal);
    letter-spacing: 1.5px;
    color: #e7dfc3;
    span {
      margin-left: 20px;
    }
    .sales-drop-icon {
      transform: rotate(0deg);
      &.open {
        transform: rotate(180deg);
      }
    }
  }
  .sales-drop-icon {
    position: absolute;
    right: 10px;
    width: 20px;
    top: 0;
  }

  .dropdown-menu {
    position: relative;
  }

  .sales-drop-btn {
    padding-left: 23px;
    appearance: none;
    -webkit-appearance: none;
  }
  .sales-drop-menu {
    background: unset;
    border: 0;
    padding: 0;
    width: 100%;
    margin-top: 21px;
  }
}
.box-edit-right {
  display: flex;
  position: absolute;
  top: 8px;
  right: 30px;
  &.box-top-20 {
    top: 20px;
    right: 20px;
  }
  button {
    white-space: nowrap;
    margin-left: 16px;
    &.sub-owner-btn {
      max-width: 97px;
      display: flex;
      align-items: center;
      padding: 7px 12px !important;
    }
  }
}
.having-modal-sec {
  .having-modal-title {
    color: #123167;
    @include font-bold(20px, normal);
    margin-bottom: 8px;
  }
  .having-modal-subtitle {
    color: #123167;
    @include font-regular(14px, normal);
    margin-bottom: 30px;
  }
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-24 {
  margin-bottom: 24px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.qr-code-check-sec {
  display: flex;
  align-items: center;
  margin-bottom: 34px;
  .qr-code-check {
    margin-left: 24px;
    margin-top: -3px;
  }
  .qr-code-check-text {
    @include font-medium(16px, normal);
    color: #885d40;
    margin-left: 10px;
  }
}
.margin-left-export {
  margin-left: 20px !important;
}
.modal-export-title {
  @include font-bold(32px, normal);
  color: #123167;
  margin-bottom: 8px;
}
.modal-export-subtitle {
  @include font-regular(14px, normal);
  color: #222;
  margin-bottom: 30px;
}
.date-picker-sec {
  padding: 9px 16px 9px 16px;
  border: solid 1px #616161;
  background-color: #fff;
  &:hover {
    box-shadow: unset;
    border-color: #885d40;
  }
  .mat-datepicker-toggle {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    &:hover {
      outline: none !important;
      box-shadow: none !important;
      background-color: transparent !important;
    }
    button:focus:not(:focus-visible) {
      background-color: transparent !important;
    }
    button {
      background: url(./../../images/svg/icons-calendar-gray.svg) no-repeat
        center;
    }
  }
  .mat-date-range-input-container {
    input {
      @include font-regular(16px, normal);
      color: #222;
      cursor: pointer;
      width: 250px;
    }
    .mat-date-range-input-mirror {
      @include font-regular(16px, normal);
      color: #222;
    }
  }
  .mat-date-range-input {
    margin-top: 3px;
  }
  mat-label {
    @include font-regular(12px, normal);
    color: #616161;
  }
  .mat-button-wrapper {
    opacity: 0;
  }
}

// .mat-calendar-body-cell {
//   &:hover {
//     background-color: #ddd5cb !important;
//   }
// }
// .mat-calendar-body-in-range{
//     background-color: #d6c788 !important;
// }
.mat-calendar-body-selected {
  background-color: #885d40 !important;
}
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: #885D40 !important;
  color: #fff;
}
.mat-calendar-body-in-range::before {
  background: rgba(#885D40, 0.5);
}
.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: #885d40 !important;
}
.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: #885d40 !important;
  &:hover {
    background: rgba(#885D40, 0.3) !important;
  }
}
.cdk-overlay-container {
  z-index: 9999 !important;
}
.qr-code-drop {
  position: absolute;
  top: 13px;
  right: 5px;
  &.commition-agent {
    right: 0px;
  }
  &.dashboard {
    top: 18px;
    @media (max-width: 1280px) {
      top: 0px;
      right: -7px;
    }
  }
  &.graph-sec {
    top: 30px;
    right: 13px;
    .dropdown-btn-none {
      color: #885d40;
      &:hover {
        color: #885d40 !important;
      }
      &:focus {
        color: #885d40 !important;
      }
    }
  }
  .form-select {
    border: none;
    @include font-semi-bold(13px, normal);
    color: #222;
    padding: 0 34px 0 0;
    background-color: transparent;
    text-align: right;
  }
}
.form-export-select-sec {
  select {
    border: 1px solid #616161;
    border-radius: 0;
    &:focus {
      box-shadow: unset;
      border-color: #885d40;
      background-color: #fff;
    }
  }
}
.pt-20 {
  padding-top: 20px !important;
}
.pb-20 {
  padding-bottom: 20px !important;
}
.modal-return-head {
  padding-top: 20px !important;
  .btn-close {
    top: 20px !important;
  }
}
.dropdown-btn-none {
  border: 0;
  background: unset;
  padding: 0;
  @include font-semi-bold(13px, normal);
  color: #222;
  &:hover,
  &:focus,
  &:active {
    color: #222 !important;
    background-color: unset !important;
    border-color: unset !important;
    box-shadow: none !important;
    outline: none !important;
  }
}
.mat-calendar-table-header {
  .mat-calendar-abbr {
    text-decoration: none !important;
  }
}

// .state-label {
//     opacity: 1 !important;
//     color: #222222;
//     font-size: 16px !important;
//     font-family: 'Manrope', sans-serif !important;
//     font-weight: 400 !important;
//     transform: scale(1) translateY(-0.6rem) translateX(0) !important;
// }
.qr-code-modal-form {
  label {
    margin-top: 8px;
    font-size: 18px !important;
    // color: #bbbbbb;
  }
  &.ng-dirty {
    label {
      margin-top: 0px;
      font-size: 14px !important;
      // color: #bbbbbb;
    }
  }
}
.mb-34 {
  margin-bottom: 34px !important;
}
#overview-report,
#commission-report,
#revenue-report {
  margin-left: -25px;
}
#overview-report,
#commission-report,
#revenue-report > div {
  margin-bottom: -20px;
}
.cursor-pointer {
  cursor: pointer;
}
.margin-copy-img {
  margin-bottom: 23px !important;
}
.copy-code-style {
  display: flex;
  align-items: center;
  justify-content: center;
  .copy-img {
    margin-left: 12px;
  }
}
