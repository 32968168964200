// Custom variables
@import "partials/variables";
@import "partials/fonts-icon";
@import "partials/fonts";
@import "partials/button";
@import "partials/form";
@import "partials/base";
@import "partials/modal";
@import "partials/table";
@import "partials/leftbar";
@import "partials/header";
@import "partials/signin";
@import "partials/pages";
@import "partials/dashboard";
@import "partials/custom";
@import "partials/sales-tracking";
.hover-full-content {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1049;
  width: 100vw;
  height: 100vh;
  background-color: rgba(#000000, 0.5);
  align-items: center;
  justify-content: center;
  display: none;
  .modal-sm {
    min-width: 327px;
  }
  .confirmation-modal h4.title {
    line-height: 1.4;
  }
}

.select-custom-view {
  position: relative;
  .select-custom {
    width: 100%;
    height: 60px;
    background: #fff;
    border: 1px solid #616161;
    color: #222222;
    border-radius: 3px;
    text-align: left;
    padding-top: 20px;
    padding-left: 15px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-bottom: 30px !important;
    .scroll-host {
      border: 1px solid #616161;
      max-height: 140px;
      background: #fff !important;
      padding-top: 10px;
      width: 100%;
      top: 25px;
      .ng-option {
        padding: 12px 30px;
        color: #222;
        @include font-regular(14px, normal);
        &:hover {
          background-color: #ddd5cb;
        }
      }
    }
    input {
      cursor: pointer !important;
    }
    .ng-dropdown-panel {
      left: 0;
    }
  }
  .custom-label {
    position: absolute;
    top: 22px;
    left: 16px;
    @include font-regular(16px, normal);
  }
  &::after {
    content: "";
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    background: url(../images/drop-arrow.svg) no-repeat;
    width: 24px;
    height: 24px;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
// Accordion
.panel-group {
  .panel {
    margin-bottom: 20px;
    .panel-default {
      border: 1px solid rgba(34, 34, 34, 0.6);
      background-color: #fff;
      border-radius: 0;
      padding: 19px 16px;

      .panel-heading {
        border: 0;
        padding: 0;
        background: none;
        .panel-title {
          .accordion-toggle {
            .btn-link {
              width: 100%;
              text-align: left;
              position: relative;
              color: #222;
              @include font-regular(16px, normal);
              padding: 0;
              text-decoration: none;
              &::after {
                content: "";
                width: 24px;
                height: 24px;
                background: url(../images/drop-arrow.svg) no-repeat;
                position: absolute;
                right: 0;
                top: -3px;
                transition: all 0.2s ease-in-out;
              }
            }
          }
        }
      }
      .card-body {
        padding: 8px 0 0 0;
        .accordion-des {
          margin-bottom: 8px;
        }
        .accordion-button-wrap {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .accordion-btn {
            color: #df2d0f;
            text-align: right;
            @include font-regular(16px, normal);
            &.edit {
              color: #222;
              margin-left: 20px;
            }
          }
        }
      }
    }
    &.panel-open {
      .btn-link {
        &::after {
          transform: rotate(-180deg);
          transition: all 0.2s ease-in-out;
        }
      }
    }
  }
}
.faq-add-btn {
  width: 158px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
