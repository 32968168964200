/*=============================================
*  Signin 2
=============================================*/

//-----------Custom variables is start here
$signin-background: #f0f5f3;
$forgot-text: #885d40 !default;
$btn-link-color: #0f1d17;
.login-bg {
  background: url(../../images//bg-login.png) no-repeat center;
  background-size: cover;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    // background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.08), #1e1e1e);
    height: 100%;
    width: 100%;
  }
  p {
    color: #fff;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 70px;
    z-index: 1;
    text-align: center;
    max-width: 509px;
    span {
      color: #bf9b41;
    }
  }
}
.signin-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  min-height: 625px;
  width: 100%;
  height: 100vh;
  background-color: #ddd5cb;
  padding: 40px 0 40px 0;
  @media (max-height: 688px) {
    padding: 20px 0 20px 0;
    min-height: 675px;
  }
}

.confirm-email-container {
  width: 100vw;
  height: 100vh;
  .form-action {
    .btn-block {
      width: 92% !important;
      position: relative;
      left: 15px;
    }
  }
}

.login-form {
  flex: inherit;
  width: 100%;
  max-width: 605px;
  color: #4d4d4d;
  border: solid 1px #885d40;
  border-radius: 6px;
  padding: 20px 80px 20px 78px;
  background-color: rgba(255, 255, 255, 0.5);
  @media (max-height: 688px) {
    padding: 10px 80px 10px 78px;
  }
  h1 {
    margin: 36px 0 11px 0;
    color: $h1-color;
    @include fontPlayfair-bold(32px, normal);
    @media (max-height: 688px) {
      margin: 16px 0 11px 0;
    }
  }
  .sub-head {
    margin-bottom: 40px;
    @include font-regular(14px, normal);
    color: #222222;
    @media (max-height: 688px) {
      margin-bottom: 30px;
    }
  }
  &.reset-success-container {
    max-width: 600px;
    text-align: center;
    padding: 36px 95px 38px;
    .signin-logo {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 63px;
      img {
        @media (max-height: 688px) {
          width: 80%;
        }
      }
    }
    .heading-wrap {
      .caption {
        margin-top: 11px;
        margin-bottom: 48px;
      }
    }
    .btn-block {
      margin-left: auto;
      margin-right: auto;
      max-width: 260px;
    }
  }
  .emailer-btn {
    width: 100%;
  }
  .forgot-link {
    color: $forgot-text;
    @include font-medium(14px, normal);
    width: 100%;
    display: inline-block;
    text-align: right;
    margin-bottom: 30px;
    &:hover {
      text-decoration: underline;
    }
  }
  .password-field {
    .form-control {
      padding-right: 65px;
    }
    .icon {
      cursor: pointer;
      position: absolute;
      right: 24px;
      font-size: 24px;
      top: 12px;
    }
  }
}

// .pw-margin-bottom {
//     margin-bottom: 3px;
// }
.pw-validation {
  margin-bottom: 34px;
  @include font-regular(12px, normal);
  color: #616161;
}

// .form-control {
//     border: unset;
// }

.form-floating > .form-control {
  padding: 9px 13px;
}
.form-floating > label {
  top: 2px;
}

// .form-floating>.form-control:-webkit-autofill~label {
//     opacity: .65;
//     transform: scale(.85) translateY(-0.2rem) translateX(0.3rem);
// }
// .form-floating>.form-control:-webkit-autofill~label {
//     opacity: 0.65;
//     transform: scale(0.8) translateY(-0.2rem) translateX(0.6rem);
// }
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.8) translateY(-0.2rem) translateX(0.15rem) !important;
}

.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 2rem;
  padding-bottom: 0.625rem;
}

.signin-logo {
  margin-bottom: 50px;
  z-index: 1;
  @media (max-height: 688px) {
    margin-bottom: 20px !important;
  }
}

.reset-pw-login-container {
  max-width: 400px;
}

.signin-footer {
  margin-top: 36px;
  display: block;
  @media (max-height: 688px) {
    margin-top: 20px;
  }
}

.emailer-container {
  background-color: rgb(255, 245, 250);
  max-width: 1024px;
  height: 100%;
  display: grid;
  align-content: center;
  justify-content: center;
  .copyright-text {
    text-align: center;
    font-size: 13px;
  }
  .emil-msg {
    background-color: #fff;
    margin: 30px 20px 21px 30px;
    padding: 30px 15px 20px 15px;
    text-align: left;
    max-width: 720px;
    width: 92%;
    p {
      font-size: 13px;
    }
    h1 {
      margin: 0 0 10px 0;
      text-align: left;
    }
  }
}

// std code
.std-code {
  position: absolute;
  top: 31px;
  left: 12px;
  @include font-regular(16px, normal);
  color: #222222;
}

.contact-num-input {
  padding-left: 47px !important;
}
