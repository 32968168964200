@mixin font-shorthand($size: $font-size-base, $lineHeight: $line-height-base, $style:normal) {
    font-size: $size;
    line-height: $lineHeight;
    font-style: $style;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

//-------main font Manrope
@mixin font-bold($size: $font-size-base, $lineHeight: $line-height-base) {
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    @include font-shorthand($size, $lineHeight);
}

@mixin font-extra-bold($size: $font-size-base, $lineHeight: $line-height-base) {
    font-family: 'Manrope', sans-serif;
    font-weight: 800;
    @include font-shorthand($size, $lineHeight);
}

@mixin font-semi-bold($size: $font-size-base, $lineHeight: $line-height-base) {
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    @include font-shorthand($size, $lineHeight);
}

@mixin font-regular($size: $font-size-base, $lineHeight: $line-height-base) {
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    @include font-shorthand($size, $lineHeight);
}

@mixin font-light($size: $font-size-base, $lineHeight: $line-height-base) {
    font-family: 'Manrope', sans-serif;
    font-weight: 300;
    @include font-shorthand($size, $lineHeight);
}

@mixin font-medium($size: $font-size-base, $lineHeight: $line-height-base) {
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    @include font-shorthand($size, $lineHeight);
}

// -------main font Playfair
@mixin fontPlayfair-shorthand($size: $font-size-base, $lineHeight: $line-height-base, $style:normal) {
    font-size: $size;
    line-height: $lineHeight;
    font-style: $style;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin fontPlayfair-bold($size: $font-size-base, $lineHeight: $line-height-base) {
    font-family: 'Playfair Display', serif;
    font-weight: 700;
    @include fontPlayfair-shorthand($size, $lineHeight);
}

@mixin fontPlayfair-semi-bold($size: $font-size-base, $lineHeight: $line-height-base) {
    font-family: 'Playfair Display', serif;
    font-weight: 600;
    @include fontPlayfair-shorthand($size, $lineHeight);
}

@mixin fontPlayfair-regular($size: $font-size-base, $lineHeight: $line-height-base) {
    font-family: 'Playfair Display', serif;
    font-weight: 400;
    @include fontPlayfair-shorthand($size, $lineHeight);
}

@mixin fontPlayfair-regular-italic($size: $font-size-base, $lineHeight: $line-height-base) {
    font-family: 'Playfair Display', serif;
    font-weight: 400;
    @include fontPlayfair-shorthand($size, $lineHeight);
}

@mixin fontPlayfair-light($size: $font-size-base, $lineHeight: $line-height-base) {
    font-family: 'Playfair Display', serif;
    font-weight: 300;
    @include fontPlayfair-shorthand($size, $lineHeight);
}

@mixin fontPlayfair-medium($size: $font-size-base, $lineHeight: $line-height-base) {
    font-family: 'Playfair Display', serif;
    font-weight: 500;
    @include fontPlayfair-shorthand($size, $lineHeight);
}