// left-nav-wrapper
.left-nav-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #123167;
  background-image: linear-gradient(164deg, #123167, #123167);
  width: 260px;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow: auto;
  z-index: 1;
  .logo-container {
    margin-bottom: 25px;
  }
  .logo-box {
    margin-top: 40px;
    text-align: center;
    img {
      max-height: 118px;
    }
  }
  &.left-nav-inner-wrapper {
    .left-nav {
      margin: 38px 0px auto;
    }
    li {
      &.active {
        a {
          // background: rgb(156, 122, 3);
          // background: -moz-linear-gradient(
          //   90deg,
          //   rgba(156, 122, 3, 1) 0%,
          //   rgba(156, 122, 3, 0.2553396358543417) 74%,
          //   rgba(156, 122, 3, 0.06) 100%
          // );
          // background: -webkit-linear-gradient(
          //   90deg,
          //   rgba(156, 122, 3, 1) 0%,
          //   rgba(156, 122, 3, 0.2553396358543417) 74%,
          //   rgba(156, 122, 3, 0.06) 100%
          // );
          // background: linear-gradient(
          //   90deg,
          //   rgba(156, 122, 3, 1) 0%,
          //   rgba(156, 122, 3, 0.2553396358543417) 74%,
          //   rgba(156, 122, 3, 0.06) 100%
          // );
          background: linear-gradient(270deg, #cbad65 0%, #885d40 72.07%);
          color: #ffffff;
          .icon,
          .nav-text {
            color: #ffffff;
          }
        }
      }
      a {
        margin: 0;
        padding: 22px 0px 21px 0px;
        display: flex;
        align-items: center;
        color: #ffffff;
        cursor: pointer;
        @include font-medium(14px, normal);
        .nav-text-logo {
          margin-left: 20px;
          min-width: 20px;
          &.warehouse-logo {
            margin-bottom: 2px;
          }
        }
        .nav-text {
          display: block;
          margin-left: 10px;
          text-transform: capitalize;
          &.warehouse-text {
            letter-spacing: normal;
          }
        }
        .icon {
          font-size: 24px;
          color: #818694;
        }
        &.active,
        &:hover {
          background: linear-gradient(270deg, #cbad65 0%, #885d40 72.07%);
          color: #ffffff;
          .icon,
          .nav-text {
            color: #ffffff;
          }
        }
      }
      .dropdown-menu {
        a {
          &.active,
          &:hover {
            background-color: #1c1c1c;
            color: #ffffff;
            .icon,
            .nav-text {
              color: #ffffff;
            }
          }
        }
      }
    }
    .faq-ul {
      padding-bottom: 30px;
      margin-top: 40px;
    }
  }
}

.dropdown {
  .btn-border-radius {
    border-radius: 0;
  }
}
