.table-container {
  width: 100%;
  border: 1px solid #e5e5e5;

  .dropdown {
    height: 24px;
    width: 24px;
    margin-left: auto;

    .dropdown-toggle {
      padding: 0px 25px 0px 0px;
    }
  }

  .user-img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    border-radius: 5px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }
}

.table-container-no-space {
  margin: 0;
  margin-bottom: 26px;
}

.table-head {
  display: flex;
  background-color: #fff;
  padding: 17px 30px 16px 20px;
  border-bottom: 1px solid #e5e5e5;

  .th {
    display: flex;
    align-items: center;
    @include font-medium(13px, normal);
    text-transform: capitalize;
    color: $th-color;

    @media (max-width: 1125px) {
      @include font-medium(11px, normal);
    }

    img {
      margin: 0 0 0 5px;
    }
  }
}

.table-body {
  display: flex;
  flex-wrap: wrap;
  margin: 0;

  .table-row {
    border-radius: 4px;
    background-color: rgba(#dcd6cc, 0.3);
    color: #4d4d4d;
    padding: 11px 30px 11px 20px;
    @include font-regular(12px, normal);

    @media (max-width: 1125px) {
      @include font-regular(10px, normal);
    }

    &:nth-child(2n) {
      background-color: #fff;
    }

    .td {
      cursor: pointer;
    }
  }
}

.table-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 100%;
  max-width: 100%;
}

.th,
.td {
  word-wrap: break-word;
  &.active-btn-td {
    .btn-primary {
      border-radius: 4px;
      @include font-regular(12px, normal);
      width: 82px;
      white-space: nowrap;

      @media (max-width: 1105px) {
        @include font-regular(12px, normal);
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &.focus {
        box-shadow: unset;
      }

      &.active {
        background-color: rgba(10, 142, 48, 0.25);
        border: solid 1px #0a8e30;
        border-radius: 3px;
        color: $table-active-button-color;
      }

      &.inactive {
        background-color: rgba(187, 187, 187, 0.33);
        border: solid 1px #bbb;
        color: $table-inactive-button-color;
      }

      &.unassigned {
        background-color: rgba(214, 199, 136, 0.33);
        border: solid 1px #d6c788;
        color: $table-unassigned-button-color;
      }
      &.empty-status {
        background-color: transparent;
        border-color: transparent;
        color: #4d4d4d;
      }
    }
  }
  &:nth-child(1) {
    flex: 0 0 0%;
    max-width: 0%;
  }

  &:nth-child(2) {
    flex: 0 0 16%;
    max-width: 16%;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  &:nth-child(3) {
    flex: 0 0 13%;
    max-width: 13%;
  }

  &:nth-child(4) {
    flex: 0 0 12%;
    max-width: 12%;
  }

  &:nth-child(5) {
    flex: 0 0 13%;
    max-width: 13%;
  }

  &:nth-child(6) {
    flex: 0 0 13%;
    max-width: 13%;
  }
  &:nth-child(7) {
    flex: 0 0 10%;
    max-width: 10%;
  }

  &:nth-child(8) {
    flex: 0 0 10%;
    max-width: 10%;
  }

  &:nth-child(9) {
    flex: 0 0 10%;
    max-width: 10%;

    @media (max-width: 1125px) {
      flex: 0 0 11%;
      max-width: 11%;
    }
  }

  &:nth-child(10) {
    flex: 0 0 5%;
    max-width: 5%;
    padding: 0;
    position: relative;

    .dropdown-toggle {
      padding: 0;
      border: 0;
    }
  }
}

.pagination-wrapper {
  display: flex;
  justify-content: flex-end;
}

.pagination {
  margin: 0px 0px 43px auto;
  color: #4d4d4d;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: unset;

  .page-item {
    .page-link {
      padding: 12px 13px 11px 15px;
      border: solid 0.5px #bbb;

      .previous-arrow {
        margin: 0 -13px 3px -13px;
      }

      .next-arrow {
        margin: 0 -13px 2px -8px;
      }
    }

    a {
      color: #4d4d4d;
      border: none;
    }
  }

  .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: $primary-button-background;
    border-color: $primary-button-background;
    border-radius: 3px;
    padding: 2px 6px;
  }

  .disabled {
    .page-link {
      border: none;
      color: #000;
      padding: 0px;
    }
  }
}

#next-page-arrow {
  color: #000;
  padding: 0 0 0 10px;
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: unset;
}

// user management start here
.table-container-user {
  .th,
  .td {
    &.active-btn-td {
      .btn-primary {
        border-radius: 4px;
        @include font-regular(12px, normal);
        width: 82px;
        white-space: nowrap;

        @media (max-width: 1105px) {
          @include font-regular(12px, normal);
          width: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &.focus {
          box-shadow: unset;
        }

        &.active {
          background-color: rgba(10, 142, 48, 0.25);
          border: solid 1px #0a8e30;
          border-radius: 3px;
          color: $table-active-button-color;
        }

        &.inactive {
          background-color: rgba(187, 187, 187, 0.33);
          border: solid 1px #bbb;
          color: $table-inactive-button-color;
        }

        &.unassigned {
          background-color: rgba(214, 199, 136, 0.33);
          border: solid 1px #d6c788;
          color: $table-unassigned-button-color;
        }
      }
    }

    &:nth-child(1) {
      flex: 0 0 0%;
      max-width: 0%;
    }

    &:nth-child(2) {
      flex: 0 0 15%;
      max-width: 15%;
      margin-right: 10px;
    }

    &:nth-child(3) {
      flex: 0 0 20%;
      max-width: 20%;
      margin-right: 10px;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }

    &:nth-child(4) {
      flex: 0 0 25%;
      max-width: 25%;
    }

    &:nth-child(5) {
      flex: 0 0 15%;
      max-width: 15%;
    }

    &:nth-child(6) {
      flex: 0 0 10%;
      max-width: 10%;
    }

    &:nth-child(7) {
      flex: 0 0 10%;
      max-width: 10%;
      position: relative;
    }

    &:nth-child(8) {
      flex: 0 0 5%;
      max-width: 5%;
    }

    // &:nth-child(9) {
    //   flex: 0 0 10%;
    //   max-width: 10%;

    //   @media (max-width: 1105px) {
    //     flex: 0 0 9%;
    //     max-width: 9%;
    //   }
    // }

    // &:nth-child(10) {
    //   flex: 0 0 5%;
    //   max-width: 5%;
    //   padding: 0;
    //   position: relative;

    //   .dropdown-toggle {
    //     padding: 0;
    //     border: 0;

    //     @media (max-width: 1105px) {
    //       padding-left: 6px;
    //     }
    //   }
    // }
  }
}

// user management start here
.table-container-user-dashboard {
  margin-top: 16px;

  .th,
  .td {
    &.active-btn-td {
      .btn-primary {
        border-radius: 4px;
        @include font-regular(12px, normal);
        width: 82px;

        @media (max-width: 1105px) {
          @include font-regular(12px, normal);
          width: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &.focus {
          box-shadow: unset;
        }

        &.issued {
          background-color: rgba(214, 199, 136, 0.33);
          border: solid 1px #d6c788;
          color: $table-unassigned-button-color;
        }
        &.active {
          background-color: rgba(10, 142, 48, 0.25);
          border: solid 1px #0a8e30;
          color: $table-active-button-color;
        }
        &.inactive {
          background-color: rgba(187, 187, 187, 0.33);
          border: solid 1px #bbb;
          color: $table-inactive-button-color;
        }
        &.assigned {
          background-color: #c7ccd9;
          border: solid 1px #123167;
          color: $table-assigned-button-color;
        }
        &.returned {
          background-color: rgba(223, 45, 15, 0.25);
          border: solid 1px #df2d0f;
          color: $table-canceled-button-color;
        }
      }
    }

    &:nth-child(1) {
      flex: 0 0 0%;
      max-width: 0%;
    }

    &:nth-child(2) {
      flex: 0 0 17%;
      max-width: 17%;
      margin-right: 10px;
    }
    &:nth-child(3) {
      flex: 0 0 15%;
      max-width: 15%;
      margin-right: 10px;
    }
    &:nth-child(4) {
      flex: 0 0 16%;
      max-width: 16%;
      margin-right: 10px;
    }

    &:nth-child(5) {
      flex: 0 0 18%;
      max-width: 18%;
    }

    &:nth-child(6) {
      flex: 0 0 15%;
      max-width: 15%;
    }

    &:nth-child(7) {
      flex: 0 0 12%;
      max-width: 12%;
    }

    &:nth-child(8) {
      flex: 0 0 7%;
      max-width: 7%;
    }
  }
}

// Guest User management start here
.table-container-guest-user {
  margin-top: 16px;
  .table-row {
    .td {
      &:nth-child(2) {
        word-break: break-all;
        img {
          width: 30px;
          height: 30px;
          min-width: 30px;
          border-radius: 50%;
          object-fit: cover;
          margin-right: 10px;
        }
      }
    }
  }
  .th,
  .td {
    &.active-btn-td {
      .btn-primary {
        border-radius: 4px;
        @include font-regular(12px, normal);
        width: 82px;

        @media (max-width: 1105px) {
          @include font-regular(12px, normal);
          width: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &.focus {
          box-shadow: unset;
        }

        &.issued {
          background-color: rgba(214, 199, 136, 0.33);
          border: solid 1px #d6c788;
          color: $table-unassigned-button-color;
        }
        &.active {
          background-color: rgba(10, 142, 48, 0.25);
          border: solid 1px #0a8e30;
          color: $table-active-button-color;
        }
        &.inactive {
          background-color: rgba(187, 187, 187, 0.33);
          border: solid 1px #bbb;
          color: $table-inactive-button-color;
        }
        &.assigned {
          background-color: #c7ccd9;
          border: solid 1px #123167;
          color: $table-assigned-button-color;
        }
        &.returned {
          background-color: rgba(223, 45, 15, 0.25);
          border: solid 1px #df2d0f;
          color: $table-canceled-button-color;
        }
      }
    }

    &:nth-child(1) {
      flex: 0 0 0%;
      max-width: 0%;
    }

    &:nth-child(2) {
      flex: 0 0 15%;
      max-width: 15%;
      padding-right: 10px;
    }
    &:nth-child(3) {
      flex: 0 0 20%;
      max-width: 20%;
      padding-right: 10px;
    }
    &:nth-child(4) {
      flex: 0 0 15%;
      max-width: 15%;
      padding-right: 10px;
    }

    &:nth-child(5) {
      flex: 0 0 12%;
      max-width: 12%;
      padding-right: 10px;
    }

    &:nth-child(6) {
      flex: 0 0 11%;
      max-width: 11%;
      padding-right: 10px;
      min-width: 100px;
    }

    &:nth-child(7) {
      flex: 0 0 12%;
      max-width: 12%;
      padding-right: 10px;
    }
    &:nth-child(8) {
      flex: 0 0 15%;
      max-width: 15%;
      padding-right: 10px;
      min-width: 140px;
    }
  }
}

// payment management vendor start here
.table-container-payment {
  .th,
  .td {
    &.active-btn-td {
      .btn-primary {
        border-radius: 4px;
        @include font-regular(12px, normal);
        width: 82px;

        @media (max-width: 1105px) {
          width: 80%;
          display: flex;
          justify-content: center;
        }

        &.focus {
          box-shadow: unset;
        }

        &.active {
          background-color: rgba(10, 142, 48, 0.25);
          border: solid 1px #0a8e30;
          border-radius: 3px;
          color: $table-active-button-color;
        }

        &.inactive {
          background-color: rgba(187, 187, 187, 0.33);
          border: solid 1px #bbb;
          color: $table-inactive-button-color;
        }

        &.failed {
          background-color: rgba(223, 45, 15, 0.25);
          border: solid 1px #df2d0f;
          color: $table-failed-button-color;
        }

        &.canceled {
          background-color: rgba(223, 45, 15, 0.25);
          border: solid 1px #df2d0f;
          color: $table-canceled-button-color;
        }

        &.pending {
          background-color: rgba(214, 199, 136, 0.33);
          border: solid 1px #d6c788;
          color: $table-pending-button-color;
        }
      }
    }

    &:nth-child(1) {
      flex: 0 0 0%;
      max-width: 0%;
    }

    &:nth-child(2) {
      flex: 0 0 15%;
      max-width: 15%;
      padding-right: 5px;
    }

    &:nth-child(3) {
      flex: 0 0 19%;
      max-width: 19%;
      padding-right: 6px;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }

    &:nth-child(4) {
      flex: 0 0 12%;
      max-width: 12%;
    }

    &:nth-child(5) {
      flex: 0 0 12%;
      max-width: 12%;
      padding-right: 5px;
    }

    &:nth-child(6) {
      flex: 0 0 12%;
      max-width: 12%;
    }

    &:nth-child(7) {
      flex: 0 0 12%;
      max-width: 12%;
    }

    &:nth-child(8) {
      flex: 0 0 12%;
      max-width: 12%;
    }

    &:nth-child(9) {
      flex: 0 0 6%;
      max-width: 6%;
      padding: 0;
      position: relative;

      .dropdown-toggle {
        padding: 0;
        border: 0;

        @media (max-width: 1105px) {
          padding-left: 6px;
        }
      }
    }
  }
}

// payment management transaction start here
.table-container-payment-transaction {
  .th,
  .td {
    &.active-btn-td {
      .btn-primary {
        border-radius: 4px;
        @include font-regular(12px, normal);
        width: 82px;

        @media (max-width: 1105px) {
          width: 80%;
          display: flex;
          justify-content: center;
        }

        &.focus {
          box-shadow: unset;
        }

        &.active {
          background-color: rgba(10, 142, 48, 0.25);
          border: solid 1px #0a8e30;
          border-radius: 3px;
          color: $table-active-button-color;
        }

        &.inactive {
          background-color: rgba(187, 187, 187, 0.33);
          border: solid 1px #bbb;
          color: $table-inactive-button-color;
        }

        &.failed {
          background-color: rgba(223, 45, 15, 0.25);
          border: solid 1px #df2d0f;
          color: $table-failed-button-color;
        }

        &.canceled {
          background-color: rgba(223, 45, 15, 0.25);
          border: solid 1px #df2d0f;
          color: $table-canceled-button-color;
        }

        &.pending {
          background-color: rgba(214, 199, 136, 0.33);
          border: solid 1px #d6c788;
          color: $table-pending-button-color;
        }
      }
    }

    &:nth-child(1) {
      flex: 0 0 0%;
      max-width: 0%;
    }

    &:nth-child(2) {
      flex: 0 0 12%;
      max-width: 12%;
    }

    &:nth-child(3) {
      flex: 0 0 25%;
      max-width: 25%;
      padding-right: 6px;
    }

    &:nth-child(4) {
      flex: 0 0 23%;
      max-width: 23%;
      padding-right: 5px;
    }

    &:nth-child(5) {
      flex: 0 0 12%;
      max-width: 12%;
    }

    &:nth-child(6) {
      flex: 0 0 10%;
      max-width: 10%;
    }

    &:nth-child(7) {
      flex: 0 0 10%;
      max-width: 10%;
    }

    &:nth-child(8) {
      flex: 0 0 8%;
      max-width: 8%;
      padding: 0;
      position: relative;

      .dropdown-toggle {
        padding: 0;
        border: 0;
      }
    }
  }
}

// QR code management start here
.table-container-qr-code {
  .th,
  .td {
    &.active-btn-td {
      .btn-primary {
        border-radius: 4px;
        @include font-regular(12px, normal);
        width: 86px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 1105px) {
          padding: 0.375rem 0px;
          width: 70px;
          @include font-regular(11px, normal);
        }

        &.focus {
          box-shadow: unset;
        }

        &.active {
          background-color: rgba(10, 142, 48, 0.25);
          border: solid 1px #0a8e30;
          border-radius: 3px;
          color: $table-active-button-color;
        }

        &.inactive {
          background-color: rgba(187, 187, 187, 0.33);
          border: solid 1px #bbb;
          color: $table-inactive-button-color;
        }

        &.unassigned {
          background-color: rgba(214, 199, 136, 0.33);
          border: solid 1px #d6c788;
          color: $table-unassigned-button-color;
        }
      }
    }

    &:nth-child(1) {
      flex: 0 0 0%;
      max-width: 0%;
    }

    &:nth-child(2) {
      flex: 0 0 3%;
      max-width: 3%;
      padding-left: 20px;

      @media (max-width: 1105px) {
        flex: 0 0 2%;
        max-width: 2%;
        padding-left: 5px;
      }
    }

    &:nth-child(3) {
      flex: 0 0 16%;
      max-width: 16%;

      @media (max-width: 1105px) {
        flex: 0 0 15%;
        max-width: 15%;
      }
    }

    &:nth-child(4) {
      flex: 0 0 15%;
      max-width: 15%;

      @media (max-width: 1105px) {
        flex: 0 0 15%;
        max-width: 15%;
      }
    }

    &:nth-child(5) {
      flex: 0 0 15%;
      max-width: 15%;

      @media (max-width: 1105px) {
        flex: 0 0 13%;
        max-width: 13%;
      }
    }

    &:nth-child(6) {
      flex: 0 0 15%;
      max-width: 15%;

      @media (max-width: 1105px) {
        flex: 0 0 14%;
        max-width: 14%;
      }
    }

    &:nth-child(7) {
      flex: 0 0 12%;
      max-width: 12%;

      @media (max-width: 1105px) {
        flex: 0 0 12%;
        max-width: 12%;
      }
    }

    &:nth-child(8) {
      flex: 0 0 12%;
      max-width: 12%;

      &.qr-btn-td {
        display: flex;
        justify-content: flex-start;
        padding: 0 !important;

        .btn {
          padding: 0 !important;
          margin: 0;

          img {
            padding: 0;
          }

          &.margin-right-18 {
            margin-right: 18px;

            @media (max-width: 1105px) {
              margin-right: 5px;
            }
          }
        }
      }

      @media (max-width: 1105px) {
        flex: 0 0 11%;
        max-width: 11%;
      }
    }

    &:nth-child(9) {
      flex: 0 0 12%;
      max-width: 12%;
      padding: 0;

      .dropdown-toggle {
        padding: 0;
        border: 0;

        @media (max-width: 1105px) {
          padding-left: 6px;
        }
      }
    }
  }
}

// QR Memory code management start here
.table-container-qr-code-detail {
  overflow: auto;
  .table-head,
  .table-body {
    min-width: 1100px;
  }
  .th,
  .td {
    &.active-btn-td {
      .btn-primary {
        border-radius: 4px;
        @include font-regular(12px, normal);
        width: 86px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 1105px) {
          padding: 0.375rem 0px;
          width: 70px;
          @include font-regular(11px, normal);
        }

        &.focus {
          box-shadow: unset;
        }

        &.issued {
          background-color: rgba(214, 199, 136, 0.33);
          border: solid 1px #d6c788;
          color: $table-unassigned-button-color;
        }
        &.active {
          background-color: rgba(10, 142, 48, 0.25);
          border: solid 1px #0a8e30;
          color: $table-active-button-color;
        }
        &.inactive {
          background-color: rgba(187, 187, 187, 0.33);
          border: solid 1px #bbb;
          color: $table-inactive-button-color;
        }
        &.assigned {
          background-color: #c7ccd9;
          border: solid 1px #123167;
          color: $table-assigned-button-color;
        }
        &.returned {
          background-color: rgba(223, 45, 15, 0.25);
          border: solid 1px #df2d0f;
          color: $table-canceled-button-color;
        }
      }
    }

    &:nth-child(1) {
      flex: 0 0 0%;
      max-width: 0%;
    }

    &:nth-child(2) {
      flex: 0 0 3%;
      max-width: 3%;
      padding-left: 20px;
    }

    &:nth-child(3) {
      flex: 0 0 14%;
      max-width: 14%;
    }
    &:nth-child(4) {
      flex: 0 0 9%;
      max-width: 9%;
    }

    &:nth-child(5) {
      flex: 0 0 12%;
      max-width: 12%;
    }

    &:nth-child(6) {
      flex: 0 0 10%;
      max-width: 10%;
    }

    &:nth-child(7) {
      flex: 0 0 12%;
      max-width: 12%;
    }

    &:nth-child(8) {
      flex: 0 0 12%;
      max-width: 12%;
    }
    &:nth-child(9) {
      flex: 0 0 10%;
      max-width: 10%;
    }

    &:nth-child(10) {
      flex: 0 0 11%;
      max-width: 11%;

      &.qr-btn-td {
        display: flex;
        justify-content: flex-start;
        padding: 0 !important;

        .btn {
          padding: 0 !important;
          margin: 0;

          img {
            padding: 0;
          }

          &.margin-right-18 {
            margin-right: 18px;

            @media (max-width: 1105px) {
              margin-right: 5px;
            }
          }
        }
      }
    }

    &:nth-child(11) {
      flex: 0 0 7%;
      max-width: 7%;
      padding: 0;
      display: flex;
      justify-content: center;

      .dropdown-toggle {
        padding: 0;
        border: 0;

        @media (max-width: 1105px) {
          padding-left: 6px;
        }
      }
    }
  }
}

// content management start here
.table-container-content {
  .table-row {
    align-items: flex-start;
    @media (max-width: 1125px) {
      @include font-regular(12px, normal);
    }
  }

  .th,
  .td {
    &.active-btn-td {
      .btn-primary {
        border-radius: 4px;
        @include font-regular(12px, normal);
        width: 86px;

        @media (max-width: 1105px) {
          width: 80%;
          display: flex;
          justify-content: center;
        }

        &.focus {
          box-shadow: unset;
        }

        &.active {
          background-color: rgba(10, 142, 48, 0.25);
          border: solid 1px #0a8e30;
          border-radius: 3px;
          color: $table-active-button-color;
        }

        &.inactive {
          background-color: rgba(187, 187, 187, 0.33);
          border: solid 1px #bbb;
          color: $table-inactive-button-color;
        }

        &.failed {
          background-color: rgba(223, 45, 15, 0.25);
          border: solid 1px #df2d0f;
          color: $table-failed-button-color;
        }
      }
    }

    &:nth-child(1) {
      flex: 0 0 20%;
      max-width: 20%;
    }

    &:nth-child(2) {
      flex: 0 0 50%;
      max-width: 50%;
      padding-right: 120px;
    }

    &:nth-child(3) {
      flex: 0 0 15%;
      max-width: 15%;
    }

    &:nth-child(4) {
      flex: 0 0 15%;
      max-width: 15%;

      .btn {
        padding: 0 !important;
      }
    }
  }
}

// user Sales start here
.table-container-sales {
  .table-head {
    padding: 17px 15px 16px 15px;
  }
  .table-body .table-row {
    padding: 11px 15px 11px 15px;
  }
  .th,
  .td {
    &.active-btn-td {
      .btn-primary {
        border-radius: 4px;
        @include font-regular(12px, normal);
        width: 86px;

        @media (max-width: 1105px) {
          @include font-regular(12px, normal);
          width: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &.focus {
          box-shadow: unset;
        }

        &.active {
          background-color: rgba(10, 142, 48, 0.25);
          border: solid 1px #0a8e30;
          border-radius: 3px;
          color: $table-active-button-color;
        }

        &.inactive {
          background-color: rgba(187, 187, 187, 0.33);
          border: solid 1px #bbb;
          color: $table-inactive-button-color;
        }

        &.unassigned {
          background-color: rgba(214, 199, 136, 0.33);
          border: solid 1px #d6c788;
          color: $table-unassigned-button-color;
        }
      }
    }

    &:nth-child(1) {
      flex: 0 0 0%;
      max-width: 0%;
    }

    &:nth-child(2) {
      flex: 0 0 10%;
      max-width: 10%;
      padding-right: 10px;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }

    &:nth-child(3) {
      flex: 0 0 20%;
      max-width: 20%;
      padding-right: 10px;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }

    &:nth-child(4) {
      flex: 0 0 10%;
      max-width: 10%;
    }

    &:nth-child(5) {
      flex: 0 0 11%;
      max-width: 11%;
    }

    &:nth-child(6) {
      flex: 0 0 11%;
      max-width: 11%;
    }

    &:nth-child(7) {
      flex: 0 0 12%;
      max-width: 12%;
    }
    &:nth-child(8) {
      flex: 0 0 12%;
      max-width: 12%;
    }
    &:nth-child(9) {
      flex: 0 0 10%;
      max-width: 10%;
    }

    &:nth-child(10) {
      flex: 0 0 4%;
      max-width: 4%;
      padding: 0;
      position: relative;

      .dropdown-toggle {
        padding: 0;
        border: 0;

        @media (max-width: 1105px) {
          padding-left: 6px;
        }
      }
    }
  }
}

// user Sales Detail start here
.table-container-sales-detail {
  .th,
  .td {
    &.active-btn-td {
      .btn-primary {
        border-radius: 4px;
        @include font-regular(12px, normal);
        width: 86px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 1105px) {
          padding: 0.375rem 0px;
          width: 70px;
          @include font-regular(11px, normal);
        }

        &.focus {
          box-shadow: unset;
        }

        &.unassigned {
          background-color: rgba(214, 199, 136, 0.33);
          border: solid 1px #d6c788;
          color: $table-unassigned-button-color;
        }

        &.issued {
          background-color: rgba(214, 199, 136, 0.33);
          border: solid 1px #d6c788;
          color: $table-unassigned-button-color;
        }
        &.active {
          background-color: rgba(10, 142, 48, 0.25);
          border: solid 1px #0a8e30;
          color: $table-active-button-color;
        }
        &.inactive {
          background-color: rgba(187, 187, 187, 0.33);
          border: solid 1px #bbb;
          color: $table-inactive-button-color;
        }
        &.assigned {
          background-color: #c7ccd9;
          border: solid 1px #123167;
          color: $table-assigned-button-color;
        }
        &.returned {
          background-color: rgba(223, 45, 15, 0.25);
          border: solid 1px #df2d0f;
          color: $table-canceled-button-color;
        }
      }
    }

    &:nth-child(1) {
      flex: 0 0 0%;
      max-width: 0%;
    }

    &:nth-child(2) {
      flex: 0 0 5%;
      max-width: 5%;
      padding-left: 20px;
    }

    &:nth-child(3) {
      flex: 0 0 23%;
      max-width: 23%;
    }

    &:nth-child(4) {
      flex: 0 0 20%;
      max-width: 20%;
    }
    &:nth-child(5) {
      flex: 0 0 20%;
      max-width: 20%;
    }
    &:nth-child(6) {
      flex: 0 0 20%;
      max-width: 20%;
    }

    &:nth-child(7) {
      flex: 0 0 12%;
      max-width: 12%;
      padding: 0;

      .dropdown-toggle {
        padding: 0;
        border: 0;

        @media (max-width: 1105px) {
          padding-left: 6px;
        }
      }
    }
  }
}

// user Sales Detail Vendor start here
.table-container-sales-detail-vendor {
  margin-bottom: 20px;
  .no-data-container {
    min-height: 220px;
  }
  .th,
  .td {
    &.active-btn-td {
      .btn-primary {
        border-radius: 4px;
        @include font-regular(12px, normal);
        width: 86px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 1105px) {
          padding: 0.375rem 0px;
          width: 70px;
          @include font-regular(11px, normal);
        }

        &.focus {
          box-shadow: unset;
        }

        &.unassigned {
          background-color: rgba(214, 199, 136, 0.33);
          border: solid 1px #d6c788;
          color: $table-unassigned-button-color;
        }

        &.issued {
          background-color: rgba(214, 199, 136, 0.33);
          border: solid 1px #d6c788;
          color: $table-unassigned-button-color;
        }
        &.active {
          background-color: rgba(10, 142, 48, 0.25);
          border: solid 1px #0a8e30;
          color: $table-active-button-color;
        }
        &.inactive {
          background-color: rgba(187, 187, 187, 0.33);
          border: solid 1px #bbb;
          color: $table-inactive-button-color;
        }
        &.assigned {
          background-color: #c7ccd9;
          border: solid 1px #123167;
          color: $table-assigned-button-color;
        }
        &.returned {
          background-color: rgba(223, 45, 15, 0.25);
          border: solid 1px #df2d0f;
          color: $table-canceled-button-color;
        }
      }
    }

    &:nth-child(1) {
      flex: 0 0 0%;
      max-width: 0%;
    }

    &:nth-child(2) {
      flex: 0 0 30%;
      max-width: 30%;
    }

    &:nth-child(3) {
      flex: 0 0 30%;
      max-width: 30%;
    }

    &:nth-child(4) {
      flex: 0 0 30%;
      max-width: 30%;
    }
    &:nth-child(5) {
      flex: 0 0 10%;
      max-width: 10%;
      padding: 0;

      .dropdown-toggle {
        padding: 0;
        border: 0;
      }
    }
  }
}

// user Sales Detail start here
.table-container-ad-management {
  .th,
  .td {
    &:nth-child(1) {
      flex: 0 0 0%;
      max-width: 0%;
    }

    &:nth-child(2) {
      flex: 0 0 20%;
      max-width: 20%;
      padding-left: 20px;
      padding-right: 20px;
      @media (max-width: 991px) {
        padding-right: 10px;
      }
    }

    &:nth-child(3) {
      flex: 0 0 20%;
      max-width: 20%;
    }

    &:nth-child(4) {
      flex: 0 0 15%;
      max-width: 15%;
    }
    &:nth-child(5) {
      flex: 0 0 15%;
      max-width: 15%;
    }
    &:nth-child(6) {
      flex: 0 0 30%;
      max-width: 30%;
    }
  }
}
// Order Management
.table-container-order-mang {
  .th,
  .td {
    &.active-btn-td {
      .btn-primary {
        border-radius: 4px;
        @include font-regular(12px, normal);
        width: 91px;
        white-space: nowrap;

        @media (max-width: 1105px) {
          @include font-regular(12px, normal);
          width: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &.focus {
          box-shadow: unset;
        }

        &.active {
          background-color: rgba(10, 142, 48, 0.25);
          border: solid 1px #0a8e30;
          border-radius: 3px;
          color: $table-active-button-color;
        }

        &.inactive {
          background-color: rgba(187, 187, 187, 0.33);
          border: solid 1px #bbb;
          color: $table-inactive-button-color;
        }

        &.unassigned {
          background-color: rgba(214, 199, 136, 0.33);
          border: solid 1px #d6c788;
          color: $table-unassigned-button-color;
        }
        &.delivered {
          border: 1px solid #0a8e30;
          background: rgba(10, 142, 48, 0.25);
          color: #0a8e30;
        }
        &.shipped {
          border: 1px solid #d1ac59;
          background: rgba(209, 172, 89, 0.3);
          color: #885d40;
        }
        &.ordered {
          border: 1px solid rgba(34, 34, 34, 0.3);
          background: rgba(34, 34, 34, 0.2);
          color: rgba(34, 34, 34, 0.6);
        }
        &.processing {
          border: 1px solid rgba(18, 49, 103, 0.3);
          background: rgba(18, 49, 103, 0.2);
          color: #123167;
        }
        &.re-shipped {
          border: 1px solid #ea7119;
          background: rgba(234, 113, 25, 0.3);
          color: #ea7119;
        }
      }
    }

    &:nth-child(1) {
      flex: 0 0 0%;
      max-width: 0%;
    }

    &:nth-child(2) {
      flex: 0 0 13%;
      max-width: 13%;
      margin-right: 10px;
    }

    &:nth-child(3) {
      flex: 0 0 8%;
      max-width: 8%;
      margin-right: 10px;
    }

    &:nth-child(4) {
      flex: 0 0 12%;
      max-width: 12%;
    }

    &:nth-child(5) {
      flex: 0 0 12%;
      max-width: 12%;
    }

    &:nth-child(6) {
      flex: 0 0 10%;
      max-width: 10%;
    }

    &:nth-child(7) {
      flex: 0 0 15%;
      max-width: 15%;
      padding-right: 10px;
    }

    &:nth-child(8) {
      flex: 0 0 13%;
      max-width: 13%;
    }

    &:nth-child(9) {
      flex: 0 0 10%;
      max-width: 10%;
    }

    &:nth-child(10) {
      flex: 0 0 5%;
      max-width: 5%;
      padding: 0;
      position: relative;

      .dropdown-toggle {
        padding: 0;
        border: 0;

        @media (max-width: 1105px) {
          padding-left: 6px;
        }
      }
    }
  }
}

.wrong-msg {
  color: #df2d0f !important;
}
.right-msg {
  color: #00852c !important;
}

.table-container-warehouse {
  .td,
  .th {
    &:nth-child(1) {
      flex: 0 0 0%;
      max-width: 0%;
    }
    &:nth-child(2) {
      flex: 0 0 3%;
      max-width: 3%;
      padding-left: 20px;
    }
    &:nth-child(3) {
      flex: 0 0 27%;
      max-width: 27%;
    }
    &:nth-child(4) {
      flex: 0 0 30%;
      max-width: 30%;
      padding-left: 20px;
    }
    &:nth-child(5) {
      flex: 0 0 30%;
      max-width: 30%;
      padding-left: 20px;
    }
    &:nth-child(6) {
      flex: 0 0 10%;
      max-width: 10%;
      justify-content: center;
    }
  }
}
.profile-user-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 12px;
  border: 1px solid #fff;
}
