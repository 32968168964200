.max-width-460 {
  max-width: 460px;
}
.max-width-526 {
  max-width: 526px;
}

.max-width-605 {
  max-width: 605px;
}

.max-width-607 {
  max-width: 607px;
}
.max-width-729 {
  max-width: 729px;
}

.max-width-1029 {
  max-width: 1029px;

  @media (max-width: 1060px) {
    max-width: 900px;
  }
}

.modal-header,
.modal-body,
.modal-footer {
  border: 0;
  padding-left: 78px;
  padding-right: 80px;
}

.modal-header {
  padding: 0px 30px 20px 30px;
  margin-top: -8px;

  &.padding-bottom-0 {
    padding-bottom: 0;
  }

  &.qr-code-header {
    margin-top: -28px;
  }
}

.modal-body {
  padding-top: 16px;
  padding-bottom: 36px;

  &.pb-8 {
    padding-bottom: 8px;
  }

  &.add-sales-padding {
    padding-bottom: 70px;
  }
}
.modal-dialog {
  &.edit-faq-modal {
    .modal-content {
      .modal-header {
        margin-top: 0;
      }
      .modal-body {
        padding-top: 50px;
        .form-floating {
          margin-bottom: 24px;
          &:last-child {
            margin-bottom: 30px;
          }
        }
      }
    }
  }
  &.delete-faq-modal {
    .modal-content {
      .modal-header {
        margin-top: 0;
        .btn-close {
          right: 20px;
          top: 20px;
        }
      }
      .modal-body {
        padding: 33px 40px 40px;
        text-align: center;
        .modal-title {
          margin-bottom: 12px;
        }
        .subhead {
          margin-bottom: 30px;
        }
        .faq-btn-wrap {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .btn {
            width: calc(50% - 10px);
          }
        }
      }
    }
  }
  &.create-passwored-admin {
    .modal-content {
      padding: 20px 78px;
      .modal-header {
        .btn-close {
          right: 10px;
          top: 30px;
        }
      }
      .modal-body {
        padding: 13px 0px 40px;
        text-align: left;
        .modal-title {
          text-align: left;
          margin-bottom: 32px;
        }
        .form-floating {
          .invalid-input-text {
            text-align: left;
          }
        }
      }
    }
  }
}

.modal-footer {
  padding: 0;
  justify-content: center;
  margin-bottom: -8px;
}

.modal-content {
  border-radius: 4px;
  width: 100%;
  margin-top: -1px;

  .search {
    margin: 15px 0;
    padding: 0px 0px 15px 15px;
    border-bottom: 2px solid #d8d8d8;

    span {
      position: relative;
      left: 48px;
      border-right: 1px solid rgb(219, 219, 219);
      padding-right: 15px;
    }

    input {
      height: 40px;
      width: 250px;
      border-radius: 20px;
      border: solid 1px rgba(0, 0, 0, 0.05);
      padding: 10px 0 10px 56px;
      background-color: #f8f8f8;
      color: #666;
      box-sizing: border-box;
    }
  }

  .close {
    font-size: 20px;
    color: $modal-close-color;

    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }

  .modal-title {
    @include font-bold(32px, normal);
    color: $modal-title;
    margin-bottom: 28px;

    &.margin-bottom-21 {
      margin-bottom: 21px;
    }

    &.margin-bottom-12 {
      margin-bottom: 12px;
    }
  }
}

.modal-form {
  .form-floating {
    input {
      border-radius: 0;
    }
  }
}

// edit about modal
.edit-about-form {
  margin-bottom: 30px;

  .btn {
    padding: 14px 79px;
  }

  p {
    @include font-regular(12px, 20px);
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: unset;
    }
  }
  &.warehouse-form {
    .unassign-qr-btn {
      width: 100%;
      margin-top: 10px;
    }
  }
}

.text-editor {
  border: 1px solid #e7dfc3;
  min-height: 316px;
  margin-bottom: 32px;
  padding-bottom: 41px;
  background-color: #fff;

  &.height-500 {
    height: 500px;
  }
}

.edit-about-btn {
  width: 100%;
  text-align: right;

  &.qr-code-btn-padding {
    width: calc(100% - 2px);
    margin: 0 auto;
    display: flex;
    align-content: center;

    .btn-outline-primary {
      padding: 14px 24px;
      width: 252px;
    }

    .primary {
      padding: 14px 24px;
      width: 252px;
    }
  }

  &.mt-32 {
    margin-top: 32px;
  }
}

.edit-about-close {
  top: 68px;
  right: 72px;
}

.dashboard-sales-modal {
  max-width: 538px;

  .modal-content {
    padding: 36px 46px;
  }

  .modal-header {
    padding: 0;
    margin-bottom: 30px;
    justify-content: space-between;

    .modal-title {
      margin-bottom: 0;
    }

    .btn-close {
      font-size: 14px;
      right: 20px;
      top: 30px;
    }
  }

  .modal-body {
    padding: 0;

    .modal-subtitle {
      @include font-regular(16px, normal);
      color: #222;
      margin-bottom: 30px;
    }

    button {
      margin-top: 10px;
    }
  }
}

.unassign-qr-btn {
  width: 212px;
}

.add-sales-header {
  .btn-close {
    top: 20px !important;
  }
}

.qr-code-body {
  position: relative;
  .modal-title {
    font-size: 30px;
    margin-bottom: 12px;
  }

  .text {
    margin: 0 0 9px;
    font-size: 18px;
    font-weight: 500;
    color: #123167;

    &.phone-number {
      margin-bottom: 18px;
    }
  }

  .address-with-btn {
    display: flex;
    align-items: center;
    margin-bottom: 34px;

    .address {
      display: flex;
      align-items: flex-start;
      font-size: 16px;
      font-weight: normal;
      color: #123167;
      margin-right: 26px;

      img {
        margin-right: 4px;
      }
    }

    .btn-link {
      display: flex;
      align-items: flex-end;
      font-size: 12px;
      font-weight: 600;
      color: #885d40;
      text-decoration: none;
      padding: 0;
    }
  }

  .qr-code-container {
    border-top: 1px solid #bbb;
    padding-top: 35px;
    display: flex;
    align-items: flex-end;

    .code-image {
      border: 4px solid #000;
      padding: 10px;
      border-radius: 12px;
      margin-right: 14px;

      img {
        max-height: 110px;
        max-width: 110px;
      }
    }

    .copy-code-style {
      font-size: 20px;
      font-weight: 600;
      color: #123167;
    }
  }
}

.edit-address-container {
  width: 415px;
  padding: 58px;
  border-radius: 6px;
  box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  position: absolute;
  top: 20%;
  right: -30%;
  display: none;
  &.active {
    display: block;
  }
  .title {
    font-size: 32px;
    font-weight: bold;
    color: #123167;
    margin-bottom: 24px;
  }
  .btn-close {
    position: absolute;
    right: 30px;
    top: 30px;
  }
  .btn-container {
    text-align: center;
    .btn {
      width: 80%;
      margin: 0 auto;
    }
  }
  .form-select {
    border-radius: 3px;
  }
}
