* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

ul,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
  text-rendering: auto;
}

ul,
ol,
li {
  list-style-type: none;
  padding: 0;
}

html,
body {
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  @include font-regular(15px, 1.2);
  color: #222222;
  -webkit-tap-highlight-color: transparent !important;
  // background-image: linear-gradient(125deg, #232323, #1a1a1a);
  font-feature-settings: "liga" 0;
  background-color: #f8f8f8;
}

*:before,
*:after {
  -webkit-tap-highlight-color: transparent !important;
}

.btn:focus {
  outline: none;
}

body,
ul,
figure {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a {
  color: $link-color;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  &:hover,
  &:focus,
  &:active {
    outline: none;
    text-decoration: none;
    cursor: pointer;
    color: darken($link-color, 10);
  }
}

p {
  @include font-regular(14px, 26px);
}

h1 {
  @include font-semi-bold(20px, normal);
  color: $h1-color;
}

h2 {
  @include font-bold(40px, 1.15);
}

h3 {
  @include font-bold(30px, 1.15);
}

h4 {
  @include font-bold(19px, 2);
}

.section-title {
  margin-bottom: 15px;

  &.remove-margin {
    margin-bottom: 0;
  }
  &.title-border-bottom {
    border-bottom: 1px solid #cdd6dc;
    padding-bottom: 17px;
  }
  &.title-dashboard-border-bottom {
    padding-bottom: 17px;
  }
}

.form-select {
  width: auto;
  border: 1px solid #DDD5CB;
  @include font-regular(13px, 23px);
  color: $select-text-color;
  padding: 9px 46px 7px 0.75rem;
  cursor: pointer;
}

.form-control {
  background: #fff;
  border: 1px solid #616161;
  color: #222222;
}

.w-327 {
  width: 327px !important;
}

.text-danger {
  color: $error-color !important;
}

.text-warning {
  color: $warning-color !important;
}

.text-success {
  color: $success-color !important;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.footer-padding {
  padding-bottom: 78px;
}

.heading-wrap {
  margin-bottom: 22px;
  h1 {
    @include font-medium(18px, 1.11);
    color: $theme-secondary-text;
    margin-bottom: 6px;
  }
  .caption {
    color: $caption-color;
    line-height: 19px;
    display: block;
  }
  .icon {
    margin-bottom: 20px;
    display: inline-block;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background: $white;
  margin: 35px 0 30px;
  opacity: 0.15;
}

.bg-grey {
  background: $theme-main-background;
}

.dropdown {
  .dropdown-toggle {
    color: $card-dropdown-icon;
    font-size: 26px;
    line-height: 10px;
    height: 50px;
    min-width: 50px;
    align-items: flex-start;
    justify-content: flex-start;
    border: solid 1px transparent;
    box-shadow: none;
    @extend %all-animation;
    &::after {
      border: none;
    }
    .dropdown-img {
      padding-left: 30px;
    }
  }
  .dropdown-menu {
    min-width: 260px;
    padding: 0;
    background-color: transparent;
    li {
      border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);
      &:last-child {
        border-bottom: 0;
      }
    }
    .dropdown-item {
      min-width: 143px;
      @include font-regular(12px, 1.15);
      padding: 22px 30px 21px 30px;
      text-transform: uppercase;
      .icon {
        font-size: 24px;
        display: inline-block;
        margin-right: 15px;
        color: $icon-color;
      }
      &:hover {
        background-color: transparent;
        color: $icon-color;
        .icon {
          color: $dropdown-text;
        }
      }
    }
    .dropdown-item-left {
      @include font-regular(12px, 1.15);
      padding: 22px 30px 21px 30px;
      text-transform: uppercase;
      display: flex;
      align-items: flex-start;
      background: rgb(156, 122, 3);
      background: -moz-linear-gradient(
        90deg,
        rgba(156, 122, 3, 1) 0%,
        rgba(156, 122, 3, 0.2553396358543417) 74%,
        rgba(156, 122, 3, 0.06) 100%
      );
      background: -webkit-linear-gradient(
        90deg,
        rgba(156, 122, 3, 1) 0%,
        rgba(156, 122, 3, 0.2553396358543417) 74%,
        rgba(156, 122, 3, 0.06) 100%
      );
      background: linear-gradient(
        90deg,
        rgba(156, 122, 3, 1) 0%,
        rgba(156, 122, 3, 0.2553396358543417) 74%,
        rgba(156, 122, 3, 0.06) 100%
      );
      width: 271px;
    }
  }
}

.alert {
  position: fixed;
  top: 22px;
  left: 50%;
  margin-left: calc(-450px / 2);
  min-height: 50px;
  width: 450px;
  border-radius: 2px;
  border: 0;
  z-index: 1032;
  display: flex;
  align-items: center;
  @include font-medium(13px, 1.77);
  .icon {
    align-self: flex-start;
    margin: 3px 5px 0 0;
    &.icon-info {
      margin-top: 7px;
    }
  }
  .close {
    font-size: 16px;
    padding: 1rem;
  }
  &.alert-success {
    color: $alert-success-color;
    background-color: $alert-success-background;
    box-shadow: 0 2px 4px 0 rgba(88, 172, 134, 0.17);
    .close {
      &:hover {
        color: darken($alert-success-color, 10%);
      }
    }
  }
  &.alert-warning {
    color: $alert-warning-color;
    background-color: $alert-warning-background;
    box-shadow: 0 2px 4px 0 rgba(218, 73, 73, 0.18);
    .close {
      &:hover {
        color: darken($alert-warning-color, 10%);
      }
    }
  }
}

.disable {
  opacity: 0.5;
  pointer-events: none;
}

.empty-screen {
  min-height: calc(100vh - 200px);
  display: flex;
  align-items: center;
  justify-content: center;
  h4 {
    @include font-medium(28px, 20px);
    color: $theme-secondary-text;
  }
}

.loader-container {
  height: 100%;
  width: 100%;
  background: rgba($body-background, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
}

.loader {
  border: 5px solid #115c3a;
  border-top: 5px solid transparent;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.btn-link {
  @include font-semi-bold(12px, normal);
  color: $btn-link-color;
  &:hover {
    color: $btn-link-color;
  }
  img {
    padding-right: 7px;
  }
}
.btn-link-view {
  @include font-semi-bold(12px, normal);
  color: $btn-link-color;
  text-decoration: none;
  padding: 0;
  &:hover {
    color: $btn-link-color;
  }
  img {
    padding-right: 7px;
  }
}
.btn-link-sales {
  @include font-semi-bold(12px, normal);
  color: #885d40;
  &:hover {
    color: #885d40;
  }
  &.sold {
    color: #616161;
  }
}

.para-wrapper {
  padding: 14px 22px 40px 14px;
}

// custom file input
.custom-file-input {
  border: 1px dashed #885d40;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 252px;
  height: 167px;
  border-radius: 6px;
  margin-right: 25px;
  z-index: 1;
  position: relative;
  background: url(./../../../assets/images/svg/add-image-logo.svg) no-repeat
    #fff center 60px;
  @media (max-width: 1130px) {
    width: 210px;
    height: 140px;
    background: url(./../../../assets/images/svg/add-image-logo.svg) no-repeat
      center 54px;
  }
  &.active {
    background-color: #fffdf6;
    border: unset;
  }
  img {
    margin-bottom: 3px;
    z-index: 5;
  }
  span {
    @include font-semi-bold(12px, normal);
    color: #e7dfc3;
    margin-top: 34px;
  }
  input[type="file"] {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
  }
  &.upload-pic {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
    position: relative;
    z-index: 2;
  }
}

.file-input-heading {
  @include font-semi-bold(18px, 22px);
  margin-bottom: 16px;
  display: inline-block;
}

// Text editor start here
.tox-toolbar__primary {
  background-color: $editor-header-bg !important;
  border: 1px solid #885d40 !important;
}

.tox-tinymce {
  border-color: $editor-border-color !important;
}

// custom file input enable
.delete-img-btn {
  position: absolute;
  padding: 0 !important;
  bottom: -1px;
  right: 1px;
  z-index: 9;
}

.custom-file-input-enable {
  border: unset;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
  }
}

.add-pic {
  a {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    input {
      display: none;
    }
    .form-label {
      margin-bottom: 0;
      cursor: pointer;
    }
  }
}

.add-pic-about {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
  z-index: 2;
  // @media(max-width: 1350px) {
  //     height: 167px;
  // }
  // @media(max-width: 1280px) {
  //     height: 124px;
  // }
  // @media(max-width: 1135px) {
  //     height: 104px;
  // }
}

.breadcrumb {
  margin-bottom: 0;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: ">";
}

.file-input-wrapper {
  margin-bottom: 25px;
}

.no-data-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  min-height: 400px;
  @media (max-height: 768px) {
    min-height: 400px;
  }
  img {
    margin-bottom: 21px;
  }
  p {
    @include font-medium(18px, 22px);
    color: #ddd5cb;
  }
}
