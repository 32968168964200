// Dashboard
.dashboard-container {
  padding: 94px 0px 25px 0px;
  .user-management-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    &.user-profile-header {
      margin-top: 30px;
    }
    .user-mangement-header-left {
      display: flex;
      align-items: flex-end;
      .search-input-group {
        width: 338px;
        @media (max-width: 1360px) {
          width: 270px;
        }
        @media (max-width: 1300px) {
          width: 250px;
        }
        @media (max-width: 1280px) {
          width: 240px;
        }
        @media (max-width: 1200px) {
          width: 200px;
        }
        input {
          height: 40px;
          &::placeholder {
            color: #616161;
          }
        }
      }
      .select-custom-height {
        padding: 3.5px 46px 3.5px 0.75rem !important;
        width: 137px !important;
      }
    }
    .status-label {
      margin-left: 20px;
      &.duration-label {
        margin-left: 10px;
        @include font-regular(12px, normal);
        color: #123167;
        margin-right: 8px;
      }
    }
    &.qr-memory-header {
      max-width: 100%;
      overflow: auto;
      .user-mangement-header-left{
        .search-input-group{
          width: 300px;
        }
      }
      .qr-duration-wrapper {
        margin-right: 0px;
        &.qr-status-wrapper {
          margin-right: 20px;
          .form-select {
            width: 115px !important;
          }
        }
      }
    }
  }
  .subs-padding {
    margin: 0 -7px 0 -7px;
  }
  .col-4-subs {
    padding-left: 7px;
    padding-right: 7px;
  }
  .subs-row {
    margin-top: 15px;
  }
  .card {
    margin: 15px 0 0px 0;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    padding: 40px 20px 0px 24px;
    border-radius: 12px;
    border: 0;
    color: #333;
    width: 100%;
    .subs-header {
      position: relative;
      bottom: 20px;
    }
    .subs-period {
      width: 89px;
      min-width: 70px;
      height: 25px;
      margin: 10px 20px 20px 0px;
      border-radius: 12.5px;
      background-color: #f3f3f3;
      padding: 5px 14px 5px 15px;
      color: #909397;
      @include font-medium(10px, normal);
      text-transform: uppercase;
      position: relative;
      bottom: 40px;
    }
    .horizontal-rule {
      background-color: rgba(87, 96, 117, 0.1);
      height: 1px;
      position: relative;
      bottom: 39px;
      margin: 0 -20px 0 -24px;
    }
    .subs-footer {
      position: relative;
      bottom: 40px;
      h3 {
        @include font-bold(14px, normal);
        color: #1b232b;
        margin: 21px 0 10px 0;
      }
      ul {
        list-style: none;
        color: #1b232b;
        @include font-regular(13px, 30px);
        li {
          &::before {
            content: "\2022";
            color: #1b232b;
            font-weight: bold;
            display: inline-block;
            width: 16px;
          }
        }
      }
    }
    .card-title {
      @include font-bold(30px, normal);
    }
    .price {
      @include font-bold(80px, 1.2);
      span {
        position: relative;
        top: -38px;
        display: inline-block;
        @include font-medium(24px, 1.2);
        margin-right: 10px;
      }
    }
  }
  .subs-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .right-side-text {
      display: flex;
      @include font-regular(14px, normal);
      color: #4d4d4d;
      .down-arrow-icon {
        padding: 0 10px 0 0px;
      }
      .dropdown-item-parmanant {
        top: 40px;
      }
    }
  }
}

.dropdown-toggle::after {
  border: unset;
}

.pagination-section {
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  .pagination {
    justify-content: flex-end;
    .pg-text-li {
      border: unset;
      margin: 0 1px;
    }
    .color-text-pg {
      color: #885d40;
    }
    .page-item {
      .page-link {
        @include font-regular(14px, normal);
        color: #4a4a4a;
      }
    }
  }
}

.left-arrow-active {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.right-arrow-disabled {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

// dropdown-item
.dropdown-menu {
  min-width: 152px;
}

.input-group {
  .search-input-logo {
    background-color: #885d40;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 0;
  }
  &.search-input-group {
    width: 24%;
    margin-right: 25px;
    @media (max-width: 1244px) {
      // width: 29%;
      margin-right: 15px;
    }
    @media (max-width: 1140px) {
      // width: 25%;
      margin-right: 20px;
    }
    .form-control {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.form-control-search {
  // color: #616161 !important;
  border: solid 1px #e5e5e5;
  background-color: #fff;
  @include font-regular(12px, normal);
}

// sign in
.signin-modal {
  max-width: 605px;
  height: 100%;
  align-items: center;
  display: flex;
  @media (max-width: 768px) {
    max-width: 100%;
    display: unset;
    height: auto;
  }
  .signin-footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.modal-header {
  justify-content: center;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    flex-direction: column;
  }
}

.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem 0rem -0.5rem 0px;
  position: absolute;
  right: 12px;
  top: 2px;
  z-index: 2;
  &.edit-about-close {
    top: 20px;
    right: 72px;
  }
  &.qr-code-close {
    top: 20px;
    right: 72px;
    font-size: 18px;
  }
}

.modal-footer-img {
  width: 100%;
  height: 34px;
}

.modal-header-img {
  width: 100%;
  height: 33px;
}

// checkbox
.form-control:focus {
  box-shadow: unset;
  border-color: $input-border-active-color;
  background-color: #fff;
}

.form-select:focus {
  box-shadow: unset;
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: unset;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 8.5px 15px;
  clear: both;
  @include font-regular(13px, 23px);
  color: #000;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item.hover,
.dropdown-item:hover {
  color: $dropdown-item-active-color;
  text-decoration: none;
  background-color: $dropdown-item-focus-background;
}

// calender
.bs-datepicker {
  left: 75px;
  border: 1px solid #616161;
  margin-top: 10px;
}

.theme-green .bs-datepicker-head {
  background-color: #fff;
  text-align: center;
}

.bs-datepicker-head button {
  color: #222222;
}

.bs-media-container {
  border: 1px solid #e7dfc3;
  border-radius: 5px;
}

.bs-days-calendar-view {
  border: 1px solid red;
}

.bs-datepicker-body {
  border: unset;
}

.bs-datepicker-body table td span {
  color: #222222;
  @include font-regular(14px, 22px);
  letter-spacing: 1.27px;
}

.bs-datepicker-body table th {
  @include font-regular(14px, 22px);
  color: #30353c;
  font-weight: 400;
  text-transform: uppercase;
  padding-right: 16px !important;
}

.bs-datepicker-body table.days span {
  padding: 0 8px;
}

.theme-green .bs-datepicker-body table td.week span {
  color: #222222;
}

.theme-green .bs-datepicker-body table td span.selected,
.theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*="select-"]:after,
.theme-green .bs-datepicker-body table td[class*="select-"] span:after {
  background-color: #885d40 !important;
  color: #fff;
  padding: 0;
}

.signin-modal-content {
  border: unset;
  padding-top: 20px;
  height: 100%;
  position: relative;
  @media (max-width: 768px) {
    min-height: 100%;
    width: 100%;
  }
  .signin-modal-header {
    border: unset;
    border-radius: unset;
    padding: 0 100px;
    @media (min-width: 768px) {
      padding: 0 16px 0 80px;
    }
  }
}

.modal-content {
  border: unset;
  padding: 0px 30px;
  @media (min-width: 922px) {
    border: 0;
    border-radius: 11px;
    padding: 0px;
  }
}

.modal {
  background-color: unset;
  @media (max-width: 768px) {
    background-color: #fff;
  }
}

.signin-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 80px 20px 78px;
  @media (max-width: 430px) {
    padding: 16px;
  }
  .signin-title {
    @include fontPlayfair-bold(32px, normal);
    color: $signin-title-color;
    margin-bottom: 11px;
    @media (max-width: 540px) {
      @include fontPlayfair-bold(28px, normal);
    }
  }
  .signin-para {
    @include font-regular(14px, 20px);
    color: $signin-para-color;
    margin-bottom: 40px;
  }
  .full-input {
    padding: 19px 16px;
    border: 1px solid #616161;
    margin-bottom: 34px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  input {
    outline: none;
    border: none;
    display: block;
    @include font-regular(16px, normal);
    color: #222222;
  }
  label {
    display: block;
    color: #616161;
    @include font-regular(16px, normal);
  }
  .signin-container {
    .forgot-pw {
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
    }
    .form-action {
      .btn-primary {
        width: 100%;
        margin: 0 0 22px 0;
      }
    }
    .forgot-link {
      color: $link-text-color;
      @include font-medium(14px, normal);
      margin-bottom: 30px;
    }
    .signup-link {
      color: $link-text-color;
      @include font-medium(14px, normal);
    }
    .signup-para {
      @include font-regular(14px, normal);
      margin-bottom: 36px;
    }
  }
}

// Input field start here
.form-floating {
  // border: 1px solid #616161;
  margin-bottom: 34px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.pw-input {
  margin-bottom: 30px;
}

.hide-eye {
  position: absolute;
  right: 0;
  cursor: pointer;
  margin-right: 16px;
}

// invalid input
.invalid-input {
  input {
    border: 1px solid red;
  }
  // margin-bottom: 4px;
}

.invalid-input-text {
  margin-top: -30px;
  margin-bottom: 34px;
  bottom: -16px;
  color: red;
  display: block;
  @include font-regular(12px, normal);
}
.invalid-input-text-err {
  position: absolute;
  bottom: -16px;
  color: red;
  display: block;
  @include font-regular(12px, normal);
}
.invalid-input-text-err-two {
  position: absolute;
  top: 59px;
  color: red;
  display: block;
  @include font-regular(12px, normal);
}
.invalid-input-text-date {
  position: absolute;
  left: 0px;
  bottom: -18px;
  color: red;
  display: block;
  @include font-regular(12px, normal);
}

// breadcrumb
.breadcrumb {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #cdd6dc;
  padding-bottom: 17px;
  .breadcrumb-item {
    &.breadcrumb-item-admin {
      a {
        @include font-semi-bold(20px, normal);
        color: $h1-color;
      }
      &.active {
        a {
          @include font-medium(16px, normal);
          color: #bbbbbb;
        }
      }
    }
  }
}

.user-profile-wrapper {
  width: 100%;
  .upgrade-plan {
    // background-color: $admin-upgrade-plan-bg-color;
    // text-align: right;
    // padding: 11px 24px 10px 24px;
    margin-bottom: 10px;
    .primary {
      background-color: #0a8e30;
      border-radius: 22px;
      border: solid 1px #fff;
      padding: 3px 11px 4px 10px;
      height: auto;
      @include font-semi-bold(12px, normal);
    }
  }
}

.user-details-section {
  background-color: #fff;
  padding: 22px;
  position: relative;
  // height: 94px;
  .user-info-row {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    flex-direction: row;
    color: #616161;
    @include font-regular(14px, normal);
    position: relative;
    .user-info-img {
      position: relative;
      display: block;
      width: 94px;
      height: 94px;
      min-width: 94px;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .user-info {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      .user-info-des {
        margin-left: 18px;
        .user-info-title {
          @include font-bold(22px, normal);
          color: #123167;
          margin-bottom: 6px;
          text-transform: capitalize;
        }
        .user-info-email {
          @include font-medium(14px, normal);
          color: #123167;
          margin-bottom: 16px;
        }
      }
    }
    .user-phone-number,
    .user-memory-code {
      @include font-semi-bold(12px, normal);
      color: #222222;
      margin-top: 30px;
      margin-bottom: 4px;
    }
  }
  .phone-num-col,
  .memory-code-col {
    @media (max-width: 1200px) {
      padding-left: 40px;
    }
  }
  .dropdown-horizontal {
    display: inline-block;
    width: 100%;
  }
  .user-address {
    margin-left: 317px !important;
  }
}

// add member admin
.add-member-admin {
  margin-bottom: 40px;
  background-color: transparent;
  padding: 21px 0;
  // border: 1px solid #cdd6dc;
  .add-member-head {
    @include font-semi-bold(18px, 22px);
    border-bottom: 1px solid #cdd6dc;
    padding-bottom: 14px;
    margin-bottom: 22px;
    display: inline-block;
    width: 100%;
    padding-left: 22px;
    padding-top: 21px;
  }
  .user-management-card {
    margin-bottom: 30px;
    background-color: #fff;
  }
}

.add-member-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 22px 0 22px;
  margin-bottom: 42px;
  position: relative;
  &.padding-right-16 {
    padding-right: 16px;
  }
  &.margin-bottom-21 {
    margin-bottom: 21px;
  }
  &.margin-bottom-62 {
    margin-bottom: 62px;
  }
  .add-member-des {
    margin-left: 0px;
    display: flex;
    align-items: center;
    .member-year {
      @include font-medium(16px, normal);
      color: #885d40;
      margin-bottom: 10px;
      display: block;
    }
    h2 {
      @include font-bold(20px, normal);
      color: #123167;
      margin-bottom: 6px;
      text-decoration: underline;
      cursor: pointer;
      &.underline-remove {
        text-decoration: none;
      }
    }
    .member-name {
      @include font-medium(14px, normal);
      color: #616161;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 16px;
      img {
        margin-right: 4px;
      }
    }
  }
  .profile-user-img-wrapper {
    max-width: 75px;
    height: 94px;
    min-width: 75px;
    display: inline-block;
    margin-right: 18px;
    &.info-img-wrapper {
      min-width: 103px;
      width: 103px;
      height: 129px;
      max-width: 103px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 16px;
    }
  }
}

.member-details-section {
  padding: 21px 22px 0 22px;
  ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #cdd6dc;
    padding-bottom: 5px;
    li {
      margin-right: 40px;
      @media (max-width: 1256px) {
        margin-right: 20px;
      }
      a {
        @include font-medium(14px, normal);
        color: #123167;
        @media (max-width: 1256px) {
          @include font-medium(13px, normal);
        }
        &.active {
          color: #885d40;
          border-bottom: 2px solid #885d40;
          padding-bottom: 5px;
        }
      }
    }
  }
}

// about
.about-section {
  margin-top: 20px;
  padding: 0 22px 35px 22px;
  min-height: 310px;
  &.padding-remove {
    padding: 0;
    margin: 30px 0 42px 0;
    min-height: unset;
  }
  p {
    @include font-regular(12px, 20px);
    margin-bottom: 24px;
    word-break: break-all;
    &.qr-code-para {
      color: #222;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      align-items: center;
      .qr-code-text {
        color: rgba(34, 34, 34, 0.6);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: inline;
        margin-left: 16px;
        align-items: center;
        img {
          margin-right: 8px;
        }
        &.location-img {
          margin-left: 30px;
        }
      }
    }
  }
  ul {
    padding: 0 0 0 22px;
  }
}

// career
.list-group-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: unset;
  color: #123167;
  @include font-regular(12px, 20px);
  margin: 10px 0;
  padding: 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  img {
    margin: 0 10px 0 -20px;
    max-width: 12px;
    max-height: 12px;
  }
  .last-list-item {
    margin-right: 10px;
  }
}

// Gallery
.gallery-section {
  .gallery-wrapper {
    ul {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 0;
      margin: 0 -10px 0 -10px;
      flex-wrap: wrap;
      li {
        width: 20%;
        padding: 0 10px;
        display: inline-block;
        margin-bottom: 20px;
        position: relative;
        .gallery-img {
          width: 100%;
          object-fit: cover;
          border-radius: 6px;
          height: 134px;
        }
        .delete-image-logo {
          position: absolute;
          bottom: 0;
          right: 10px;
          cursor: pointer;
        }
      }
    }
  }
}

.qr-code-section {
  margin-top: 45px;
  &.padding-35 {
    margin-bottom: 35px !important;
  }
  .qr-code-wrapper {
    border: 1px solid #ddd5cb;
    border-radius: 6px;
    width: 60%;
    text-align: center;
    padding: 34px 0 41px 0;
    &.padding-40 {
      padding: 40px 0;
    }
    .qr-code-text {
      @include font-medium(14px, normal);
      margin-bottom: 8px;
      display: block;
    }
    .qr-code-img {
      width: 141px;
      height: 144px;
      display: block;
      margin: 0 auto 10px auto;
      .qr-scan-img {
        width: 100%;
        object-fit: cover;
        border: 10px solid #000;
        padding: 10px;
        border-radius: 8px;
      }
    }
    .memory-code-text {
      @include font-semi-bold(18px, normal);
      color: #123167;
      margin-bottom: 38px;
      &.margin-remove {
        margin-bottom: 0;
      }
    }
  }
}

.radio-btn-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  .form-check {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &:first-child {
      margin-right: 70px;
    }
    label {
      margin-left: 14px;
    }
  }
}

.qr-code-number {
  width: 100%;
  display: flex;
  flex-direction: row;
  .qr-number {
    padding: 14px 25px;
    border: solid 1px #d6c788;
    border-radius: 6px;
    margin-right: 25px;
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    &.active {
      background-color: #885d40;
      color: #fff;
    }
  }
}

// deactivate user
.deactivate-user {
  @include font-regular(12px, normal);
  background-color: #eeeeee;
  padding: 5px 22px;
  display: flex;
  align-items: center;
  word-break: break-word;
  span {
    @include font-semi-bold(12px, normal);
    display: inline-block;
    margin-right: 8px;
  }
}

.bottom-margin-wrapper {
  background-color: #f8f8f8;
  height: 30px;
}

// information management
.info-profile-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  .info-des-col {
    border-right: 1px solid #bbbbbb;
    .add-member-des {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .preview-btn-col {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    .btn {
      padding: 0;
      @include font-semi-bold(14px, normal);
    }
    .text-preview {
      text-decoration: underline;
      padding: 0;
    }
  }
  .added-text {
    padding-left: 8px;
    margin-right: 40px;
    p {
      @include font-regular(12px, normal);
      color: #616161;
      &:first-child {
        margin-bottom: 10px;
      }
    }
  }
  .info-date-col {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
  }
  .info-date {
    p {
      @include font-medium(12px, normal);
      &:first-child {
        margin-bottom: 10px;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

.info-profile-dropdown {
  position: absolute;
  top: 0;
  right: 0;
  .btn {
    padding: 0;
  }
}

.info-edit-btn {
  .btn-outline-secondary {
    border: solid 1px #885d40;
    @include font-semi-bold(12px, normal);
    color: #885d40;
    display: flex;
    align-items: center;
    padding: 6px 19px;
    &:hover {
      color: #885d40 !important;
      border: solid 1px #885d40 !important;
      background-color: #fff !important;
    }
    img {
      padding-right: 6px;
    }
  }
}

// QR code management start here
.qr-duration-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .status-label {
    @include font-regular(12px, normal);
    color: #123167;
    margin-right: 8px;
    @media (max-width: 1105px) {
      margin-right: 4px;
    }
  }
  .duration-label {
    margin-left: 25px;
    @media (max-width: 1140px) {
      margin-left: 15px;
    }
  }
  &.qr-status-wrapper {
    &.margin-right-25 {
      margin-right: 25px;
      @media (max-width: 1235px) {
        margin-right: 12px;
      }
      @media (max-width: 1105px) {
        margin-right: 8px;
      }
    }
  }
}
.custom-select-right {
  position: absolute;
  right: 0;
  bottom: 0;
}
.text-red {
  color: #df2d0f !important;
}
.select-search-height {
  margin-bottom: 16px;
  input {
    height: 40px;
  }
}

.assign-status {
  display: flex;
  position: absolute;
  right: 0;
  align-items: center;
  top: -46px;
  span {
    color: #123167;
  }
  select {
    margin-left: 8px;
    padding: 3.5px 46px 3.5px 0.75rem !important;
    width: 120px !important;
  }
}

.assigned-mangement-header-left {
  position: relative;
}
.text-preview {
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
  padding: 0 30px;
  display: block;
  white-space: normal;
  word-break: break-word;
}
.user-memory-code-wrap {
  display: flex;
  align-items: center;
}
.momory-code-info {
  display: flex;
  flex-direction: column;
  .member-code {
    color: #222;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 5px;
  }
  .member-code-data {
    color: rgba(34, 34, 34, 0.6);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &.plan-type {
    margin-left: 58px;
  }
}
.modal-body.owner-modal-body {
  padding: 30px 30px 50px;
  @media (max-width: 767px) {
    padding: 16px;
  }

  .modal-title {
    margin-top: 20px;
    margin-bottom: 32px;
    color: #123167;
    line-height: normal;
    @include fontPlayfair-semi-bold(38px, normal);
    @media (max-width: 767px) {
      font-size: 24px;
      margin-top: 0;
      padding-top: 20px;
    }
  }
  .btn-primary-btn {
    width: 250px;
    height: 50px;
  }
  .co-owner-footer {
    display: flex;
    justify-content: center;
  }
}
.owner-list-wrap {
  margin-bottom: 40px;
  max-height: 267px;
  overflow: auto;
  padding-right: 18px;
  min-height: 240px;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    @media (max-width: 767px) {
      width: 0;
    }
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ddd5cb;
    border-radius: 10px;
  }
  @media (max-width: 767px) {
    max-height: 450px;
    margin-bottom: 30px;
  }
  .table-container {
    .table-head {
      margin-bottom: 30px;
      border: 0;
    }
    @media (max-width: 767px) {
      min-width: 600px;
    }
    .table-body {
      display: flex;
      flex-direction: column;
      margin: 0;
      .table-row {
        background-color: #ffffff;
        margin-bottom: 16px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0;
        .profile-img-wrap {
          img {
            width: 32px;
            height: 32px;
            object-fit: cover;
            border-radius: 50%;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
.owner-modal-header {
  z-index: 1;
  position: relative;
  .btn-close {
    position: absolute;
    top: 26px;
    right: 26px;
    @media (max-width: 560px) {
      top: 10px;
    }
  }
}
.manage-co-owner-modal {
  max-width: 729px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.th .sort-icon-wrap {
  display: flex;
  margin-left: 6px;
}
.th .sort-icon-wrap img {
  cursor: pointer;
  margin-left: 0;
}
.co-owner-table {
  border: 0;
  .table-row {
    .th,
    .td {
      &:nth-child(1) {
        flex: 0 0 33%;
        max-width: 33%;
        height: 32px;
        .ellipsis-text {
          max-width: 110px;
          display: inline-block;
        }
      }
      &:nth-child(2) {
        flex: 0 0 33%;
        max-width: 33%;
        height: 32px;
        .ellipsis-text {
          max-width: 180px;
          display: inline-block;
        }
      }
      &:nth-child(3) {
        flex: 0 0 20%;
        max-width: 20%;
        height: 32px;
      }
      &:nth-child(4) {
        flex: 0 0 14%;
        max-width: 14%;
        height: 32px;
      }
      .ellipsis-text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
.tox-toolbar__primary {
  background-color: #ddd5cb !important;
  border: solid 1px #885d40 !important;
  // border-radius: 2px !important;
  z-index: 5;
}
