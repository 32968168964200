.btn {
    @include font-medium(16px, 1.38);
    border-radius: 0;
    margin: 0px;
    cursor: pointer;
    &.big {
        height: 60px;
        padding: 20px 0;
    }
    &.btn-block {
        width: 100%;
    }
    &.medium {
        height: 32px;
        padding: 6px 15px 5px;
    }
    &.w-260 {
        width: 260px;
    }
    &.primary {
        color: $primary-button-text;
        border: 1px solid transparent;
        background: $primary-button-background;
        padding: 14px 18px 14px 19px;
        outline: none;
        &:hover,
        &:focus,
        &:active {
            background: $primary-button-background;
            border-color: transparent;
            box-shadow: none;
        }
        &.disabled,
        &:disabled {
            opacity: 0.5;
        }
    }
    &.btn-outline-primary {
        border: 1px solid $outline-primary-border-color;
        color: $outline-primary-button-text;
        background-color: #fff;
        padding: 14px 18px 14px 19px;
        &:hover {
            background: $primary-button-background;
            color: $primary-button-text;
            outline: none;
            box-shadow: none;
        }
        &:focus,
        &:active {
            outline: none !important;
            box-shadow: none;
        }
        &.margin-right-25 {
            margin-right: 21px;
        }
    }
    .edit-category-btn {
        text-transform: uppercase;
        button {
            width: 159px;
            height: 48px;
            text-transform: uppercase;
        }
    }
    &.secondary {
        color: $secondary-button-text;
        border: 0;
        border-color: transparent;
        background: $secondary-button-background;
        &:hover,
        &:focus,
        &:active {
            border-color: transparent;
        }
        &.disabled,
        &:disabled {
            opacity: 0.5;
        }
    }
    .question-header {
        .btn-secondary {
            height: 48px;
            width: 200px;
            background-color: #ffffff;
        }
    }
    &.danger {
        color: #ffffff;
        border: 0;
        border-color: transparent;
        background: #da4949;
        height: 48px;
        padding: 13px 28px;
        &:hover,
        &:focus,
        &:active {
            border-color: transparent;
        }
        &.disabled,
        &:disabled {
            opacity: 0.5;
        }
    }
    &.link {
        color: $btn-link-text;
        background: $btn-link-background;
        border-color: $btn-link-border;
        @extend %all-animation;
        &:hover,
        &:focus,
        &:active {
            color: $white;
            background: darken($btn-link-background, 10%);
            border-color: darken($btn-link-border, 10%);
        }
        &.disabled,
        &:disabled {
            color: rgba($btn-link-text, 0.5);
            background: $btn-link-background;
            border-color: $btn-link-border;
        }
    }
    &.disabled,
    &:disabled {
        opacity: 0.65;
    }
    &.show{
        border-color: transparent !important;
    }
}

.add-video {
    color: #115c3a;
    border: 0;
    border-color: transparent;
    background: #d7efe4;
    &:hover,
    &:focus,
    &:active {
        border-color: transparent;
    }
    &.disabled,
    &:disabled {
        opacity: 0.5;
    }
}

.padding-btn-custom {
    padding: 8px 12px !important;
}

.btn-close:focus {
    box-shadow: unset;
}