.button-deactivate {
  @include font-semi-bold(12px, normal);
  color: #df2d0f;
  border: solid 1px #df2d0f;
  background-color: #fff;
  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
    background-color: #df2d0f;
    color: #fff;
  }
}
.button-activate {
  @include font-semi-bold(12px, normal);
  color: #00852c;
  border: solid 1px #00852c;
  background-color: #fff;
  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
    background-color: #00852c;
    color: #fff;
  }
}
.back-title {
  @include font-regular(14px, normal);
  color: #123167;
  cursor: pointer;
  display: flex;
  align-items: center;
  img {
    margin-right: 5px;
  }
}
.sales-box-wrapper {
  border: solid 1px #ddd5cb;
  padding: 30px;
  background-color: #fff;
  margin-bottom: 44px;
  .box-titile-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .box-titile-left {
      margin-bottom: 18px;
      .box-titile-sec {
        display: flex;
        align-items: center;
        margin-bottom: 2px;
      }
      .box-titile {
        @include font-bold(22px, normal);
        color: #123167;
        margin-right: 12px;
      }
      .box-email {
        @include font-medium(14px, normal);
        color: #222;
      }
    }
    .box-titile-right {
      button {
        margin-left: 16px;
      }
    }
  }
  .box-phone-sec {
    display: flex;
    align-items: center;
    @include font-medium(14px, normal);
    color: #222;
    margin-bottom: 21px;
    .box-phone {
      margin-right: 16px;
    }
    img {
      width: 14px;
      height: auto;
      margin-right: 6px;
    }
  }
  .qr-code-wrapper-sec {
    display: flex;
    align-items: center;
  }
  .qr-code-wrapper {
    display: flex;
    align-items: center;
    border: solid 1px rgba(#885d40, 0.3);
    background-color: #ddd5cb;
    padding: 15px 12px;
    &.qr-code-flex {
      display: inline-flex;
    }
    .qr-code-sec {
      border-right: 1px solid rgba(156, 122, 3, 0.5);
      margin-right: 20px;
      padding-right: 20px;
      text-align: center;
      &:last-child {
        border-right: 0;
        margin-right: 0;
        padding-right: 0;
      }
      .qr-code-title {
        @include font-regular(12px, normal);
        color: #222;
        margin-bottom: 5px;
      }
      .qr-code-num {
        @include font-bold(18px, normal);
        color: #885d40;
      }
    }
  }
}
.assigned-qr-code {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0;
  .code-title {
    @include font-bold(20px, normal);
    color: #222;
  }
}

.button-primary {
  @include font-regular(12px, normal);
  padding: 2px 8px;
  &.focus {
    box-shadow: unset;
  }
  &.active {
    background-color: rgba(10, 142, 48, 0.25);
    border: solid 1px #0a8e30;
    border-radius: 3px;
    color: $table-active-button-color;
  }
  &.inactive {
    background-color: rgba(187, 187, 187, 0.33);
    border: solid 1px #bbb;
    color: $table-inactive-button-color;
  }
  &.unassigned {
    background-color: rgba(214, 199, 136, 0.33);
    border: solid 1px #d6c788;
    color: $table-unassigned-button-color;
  }
}
.sales-edit-btn {
  @include font-semi-bold(12px, normal);
  padding: 7px 19px !important;
  &:hover {
    svg {
      g {
        stroke: #fff;
      }
    }
  }
}
.sales-profile-btn {
  padding: 0;
  height: 30px;
  width: 140px;
}
.sales-code-btn {
  padding: 10px 16px !important;
}

.ad-management-wrapper {
  .save-right {
    text-align: right;
    margin-bottom: 20px;
    button {
      padding: 9px;
      width: 194px;
    }
  }
  .ad-management-box {
    background: #fff;
    padding: 20px 20px 40px 40px;
    .ad-management-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .ad-title-sec {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .ad-title-sec-left {
          display: flex;
          align-items: center;
          .title {
            @include font-bold(16px, normal);
            color: #123167;
            margin-right: 20px;
          }
          .title-switch {
            line-height: 0;
            label {
              line-height: 0;
            }
          }
        }
        .ad-title-sec-right {
          button {
            width: 156px;
            height: 40px;
            padding: 0;
          }
        }
      }
      .ad-title-total {
        @include font-medium(14px, normal);
        color: #222;
        margin-left: 30px;
        span {
          @include font-bold(18px, normal);
          color: #885d40;
        }
      }
    }
    .ad-management-middle {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .modal-form {
        width: 100%;
      }
      select {
        border-radius: 0;
      }
      .form-input-sec {
        display: flex;
        align-items: center;
        margin: 0 -10px;
        width: calc(100% + 20px);
        .form-control {
          border-radius: 0 !important;
        }
        .form-floating {
          width: 33.33%;
          margin: 0 10px;
        }
      }
    }
    .ad-management-bottom {
      display: flex;
      align-items: center;
      border: 1px solid #ddd5cb;
      background-color: rgba(220, 214, 204, 0.3);
      padding: 32px;
      justify-content: center;
      position: relative;
      max-width: 980px;
      height: 120px;
      margin: 0 auto;
      .upload-banner-input {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        cursor: pointer;
        font-size: 0;
      }
      .upload-banner-sec {
        text-align: center;
        .upload-title {
          @include font-semi-bold(18px, normal);
          color: #123167;
          margin-bottom: 10px;
        }
        .upload-subtitle {
          @include font-regular(14px, normal);
          color: #885d40;
          margin-bottom: 5px;
          a {
            text-decoration: underline;
            color: #885d40;
          }
        }
      }
    }
  }
}

.switchSmall {
  display: inline-block;
}
.switchSmall input {
  display: none;
}
.switchSmall small {
  display: inline-block;
  width: 44px;
  height: 27px;
  background: #dadada;
  border-radius: 30px;
  position: relative;
  cursor: pointer;
}
.switchSmall small:before {
  content: "";
  position: absolute;
  width: 23px;
  height: 23px;
  background: #fff;
  border-radius: 50%;
  top: 2px;
  left: 3px;
  transition: 0.3s;
  box-shadow: -3px 0 3px rgba(0, 0, 0, 0.1);
}
.switchSmall input:checked ~ small {
  background: #885d40;
  transition: 0.3s;
}
.switchSmall input:checked ~ small:before {
  transform: translate(16px, 0px);
  transition: 0.3s;
}
.unassign-btn-qr-code {
  position: absolute;
  right: 42px;
  margin-top: -72px;
}
.commission-left {
  width: 315px;
  margin-left: 20px;
  .qr-code-sec {
    text-align: left !important;
  }
}
