@font-face {
    font-family: 'icomoon';
    src: url('../../icon-font/icomoon.eot?j55jpi');
    src: url('../../icon-font/icomoon.eot?j55jpi#iefix') format('embedded-opentype'), url('../../icon-font/icomoon.ttf?j55jpi') format('truetype'), url('../../icon-font/icomoon.woff?j55jpi') format('woff'), url('../../icon-font/icomoon.svg?j55jpi#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon', serif !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-confirmation-icon2 .path1:before {
    content: "\e92b";
    color: rgb(255, 62, 157);
    opacity: 0.0500;
}

.icon-confirmation-icon2 .path2:before {
    content: "\e92c";
    margin-left: -1em;
    color: rgb(255, 62, 157);
}

.icon-confirmation-icon11 .path1:before {
    content: "\e92d";
    color: rgb(255, 62, 157);
    opacity: 0.0500;
}

.icon-confirmation-icon11 .path2:before {
    content: "\e92e";
    margin-left: -1em;
    color: rgb(255, 62, 157);
}

.icon-down-arrow2:before {
    content: "\e92f";
}

.icon-icon-create1:before {
    content: "\e930";
    color: #818694;
}

.icon-icon-cross1:before {
    content: "\e931";
    color: #fff;
}

.icon-icon-crown1:before {
    content: "\e932";
    color: #818694;
}

.icon-icon-dashboard1:before {
    content: "\e933";
}

.icon-icon-delete1:before {
    content: "\e934";
    color: #818694;
}

.icon-icon-edit1:before {
    content: "\e935";
    color: #818694;
}

.icon-icon-eye1:before {
    content: "\e936";
    color: #818694;
}

.icon-icon-eye-close1:before {
    content: "\e937";
    color: #818694;
}

.icon-icon-four-square1:before {
    content: "\e938";
    color: #818694;
}

.icon-icon-key1:before {
    content: "\e939";
    color: #818694;
}

.icon-icon-message1:before {
    content: "\e93a";
    color: #818694;
}

.icon-icon-message-small1:before {
    content: "\e93b";
    color: #fff;
}

.icon-icon-remove1:before {
    content: "\e93c";
    color: #ff6961;
}

.icon-icon-right1:before {
    content: "\e93d";
    color: #22bc75;
}

.icon-icons-fitness .path1:before {
    content: "\e93e";
    color: rgb(255, 255, 255);
}

.icon-icons-fitness .path2:before {
    content: "\e93f";
    margin-left: -1em;
    color: rgb(105, 105, 109);
}

.icon-icon-signout1:before {
    content: "\e940";
    color: #818694;
}

.icon-icons-love .path1:before {
    content: "\e941";
    color: rgb(255, 255, 255);
}

.icon-icons-love .path2:before {
    content: "\e942";
    margin-left: -1em;
    color: rgb(119, 119, 119);
}

.icon-icons-upload-tag .path1:before {
    content: "\e943";
    color: rgb(255, 255, 255);
}

.icon-icons-upload-tag .path2:before {
    content: "\e944";
    margin-left: -1em;
    color: rgb(119, 119, 119);
}

.icon-icon-user1:before {
    content: "\e945";
    color: #818694;
}

.icon-icon-user-public1:before {
    content: "\e946";
    color: #818694;
}

.icon-info-icon1:before {
    content: "\e947";
    color: #dbdbdb;
}

.icon-play-icon1:before {
    content: "\e948";
    color: #dbdbdb;
}

.icon-search-icon2:before {
    content: "\e949";
    color: #bababa;
}

.icon-sort-icon2:before {
    content: "\e94a";
}

.icon-user-img2 .path1:before {
    content: "\e94b";
    color: rgb(255, 255, 255);
    opacity: 0.1;
}

.icon-user-img2 .path2:before {
    content: "\e94c";
    margin-left: -1em;
    color: rgb(129, 134, 148);
}